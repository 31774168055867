import React from 'react';
import styled, { css } from 'styled-components';
import Container from './container';
import Blob from './blobs';

const BlockRow = ({
  className,
  children,
  blockBackground,
  template,
  block,
}) => (
  <Container>
    <div className={className}>
      {blockBackground?.includes('blob') && (
        <BlobWrapper block={block} template={template}>
          <Blob
            block={block}
            mirror={template === 'text-image-right'}
            color={
              (blockBackground?.includes('PastelOrange') && 'pastelOrange')
              || (blockBackground?.includes('LightGray') && 'gray1')
              || (blockBackground?.includes('PastelPink') && 'pastelPink')
            }
          />
        </BlobWrapper>
      )}
      <div css={`${blockBackground?.includes('blob') && css`position: relative; z-index: 2;`}`}>
        {children}
      </div>
    </div>
  </Container>
);


// a center block can have a small offset to the right of left
const centerBlockOffSet = (position) => css`background-position: ${position} 5rem center;`;

const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  ${(props) => props.block === 'case' && css`
    ${props.template === 'visuals-content' && css`
      text-align: right !important;
      ${Blob} {
        margin-right: 5rem;
      }
    `}
    ${props.template === 'content-visuals' && css`
      text-align: left;
      ${Blob} {
        margin-left: 5rem;
      }
    `}
  `}
`;

export default styled(BlockRow)`
  margin-bottom: ${(props) => props.theme.sectionSpace};
  ${(props) => props.blockBackground && css`
    padding: 2rem 0;

    ${props.blockBackground.includes('blob') && css`
      position: relative;
    `}

    ${props.blockBackground.includes('square') && css`
      ${props.blockBackground.includes('PastelOrange') && css`
        background: linear-gradient(${props.theme.pastelOrange}, ${props.theme.pastelOrange});
      `}
      ${props.blockBackground.includes('LightGray') && css`
        background: linear-gradient(${props.theme.gray1}, ${props.theme.gray1});
      `}
      ${props.blockBackground.includes('PastelPink') && css`
        background: linear-gradient(${props.theme.pastelPink}, ${props.theme.pastelPink});
      `}
      ${props.blockBackground.includes('Center') && css`
        background-repeat: no-repeat;
        background-size: calc(100vw - (${props.theme.grid.containerGap} * 2)) 75%;
        background-position: center bottom;
        @media all and (min-width: ${props.theme.breakingpoints.lg}) {
          background-size: 60% auto;
        }
        ${props.block === 'text-image' && css`
          @media all and (min-width: ${props.theme.breakingpoints.lg}) {
            ${(props.template === 'text-image-right' && centerBlockOffSet('left'))}
            ${(props.template === 'text-image-left' && centerBlockOffSet('right'))}
          }
        `}

        ${props.block === 'partner' && css`
          ${(props.template === 'visuals-content' && centerBlockOffSet('right'))}
          ${(props.template === 'content-visuals' && centerBlockOffSet('left'))}
        `}

        ${props.block === 'case' && css`
          background-size: auto 50%;
          background-position: center bottom;
          @media all and (min-width: ${props.theme.breakingpoints.lg}) {
            background-size: 50% auto;
            ${(props.template === 'visuals-content' && centerBlockOffSet('right'))}
            ${(props.template === 'content-visuals' && centerBlockOffSet('left'))}
          }
        `}

      `}
    `}
  `}
`;

import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Container from '../../components/container';

export const query = graphql`
  fragment ImageBlock on MarkdownRemarkFrontmatterBlocks {
    template
    imageAlt
    imageBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
`;

const Image = ({
  imageAlt,
  template,
  imageBlockImage,
}) => (
  <StyledContainer template={template} small>
    <PreviewCompatibleImage image={imageBlockImage} alt={imageAlt} />
  </StyledContainer>
);

const StyledContainer = styled(Container)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
  ${PreviewCompatibleImage} {
    ${(props) => (props.template === 'center') && css`
      margin: 0 auto;
    `}
    ${(props) => (props.template === 'left') && css`
      /* Do nothing */
    `}
    ${(props) => (props.template === 'right') && css`
      margin-left: auto;
    `}
  }
`;

export default Image;

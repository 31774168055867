import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Icon from '../../layout/icons';
import Container from '../../components/container';
import Content from '../../components/content';

export const query = graphql`
  fragment QuoteBlock on MarkdownRemarkFrontmatterBlocks {
    text
    title
    subtitle
  }
`;

const Quote = (props) => {
  const {
    className,
    title,
    subtitle,
    text,
  } = props;
  return (
    <section className={className}>
      <StyledContainer>
        <Blob />
        <TitleWrapper>
          <StyledContent>
            <h2 dangerouslySetInnerHTML={{ __html: title }} />
            {subtitle && (
              <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
            )}
          </StyledContent>
        </TitleWrapper>
        <Wrapper>
          <StyledIcon icon="quote" />
          <StyledContent>
            <p>
              {text}
            </p>
          </StyledContent>
        </Wrapper>
      </StyledContainer>
    </section>
  );
};

const Blob = styled.span`
  background: ${(props) => props.theme.gray1};
  height: 70%;
  width: 100%;
  clip-path: polygon(calc(100% - 90px) 0,100% 120px,100% 100%,90px 100%,0 calc(100% - 120px),0 0);
  left: 0;
  position: absolute;
  top: 0;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    width: 30%;
    left: calc(${(props) => props.theme.grid.containerGap} * 2);
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  padding-top: 1rem;
  h2 {

  }
  h3 {
    font-size: 1rem;
  }
`;

const StyledIcon = styled(Icon)`
  position: relative;
  display: block;
  width: 5rem;
  margin: 1rem 0 0 0;
  color: ${(props) => props.theme.gray2};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin: 1rem auto 0 auto;
  }
`;

const StyledContent = styled(Content)`
  position: relative;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    max-width: 30rem;
  }
`;

const Wrapper = styled.div`

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 6fr;
    grid-gap: ${(props) => props.theme.grid.columnGap};
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding-left: 4rem;
  }
`;

export default styled(Quote)`
  margin-bottom: ${(props) => props.theme.sectionSpace};
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import Icon from '../../layout/icons';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Button from '../../components/button';
import i18n from '../../i18n';
import { push } from '../../analytics';

export const serverQuery = graphql`
  fragment PricingServer on MarkdownRemarkFrontmatter {
    pricingServerId
    title
    pricingServerPlatform
    internalName
    pricingServerIcon {
      publicURL
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    isPreferred
  }
`;

export const pricingPlanQuery = graphql`
  fragment PricingPlan on MarkdownRemarkFrontmatter {
    title
    subtitle
    price
    setupfee

    specs {
      ram
      cpu
      storage
      disk
    }

    pricingPlanServer {
      frontmatter {
        ...PricingServer
      }
    }

    pricingPlanOptions {
      included
      option {
        id
        frontmatter {
          title
        }
      }
    }

    internalLink {
      frontmatter {
        slug
      }
    }
    externalLink
  }
`;

const PricingPlan = ({
  setShowAllPlanInfo,
  className,
  title,
  subtitle,
  price,
  setupfee,

  specs: {
    ram,
    cpu,
    storage,
    disk,
  },

  pricingPlanServer,
  pricingPlanOptions,

  internalLink,
  externalLink,
}) => (
  <article className={className}>
    <Header>
      <div>
        <h4 css="font-family: 'Airbnb Cereal App Medium';">{pricingPlanServer?.frontmatter?.title}</h4>
      </div>
      <div>
        <PreviewCompatibleImage
          image={pricingPlanServer?.frontmatter.pricingServerIcon}
          alt={title}
        />
      </div>
    </Header>

    <Title>{title}</Title>
    <SubTitle>{subtitle}</SubTitle>

    <Table>
      <TableRow>
        <span>{i18n('pricingPlanRAM', 'RAM')}</span>
        <span>{ram}</span>
      </TableRow>
      <TableRow>
        <span>{i18n('pricingPlanCPU', 'CPU')}</span>
        <span>{cpu}</span>
      </TableRow>
      <TableRow>
        <span>{i18n('pricingPlanStorage', 'Opslagruimte')}</span>
        <span>{storage}</span>
      </TableRow>
      <TableRow>
        <span>{i18n('pricingPlanDisk', 'Opslagtype')}</span>
        <span>{disk}</span>
      </TableRow>
      <TableRow topBorder largerContent>
        <span>{i18n('pricingPlanMonthly', 'Vanaf p/mnd')}</span>
        <span>{price},-</span>
      </TableRow>
      <TableRow>
        <span>{i18n('pricingPlanSetupFee', 'Setup fee')}</span>
        <span>{setupfee},-</span>
      </TableRow>
    </Table>
    <CtaWrapper>
      <StyledButton
        variant="primaryGradient"
        url={internalLink?.frontmatter?.slug || externalLink || '/contact'}
        onClick={() => push({
          event: 'orderNow',
          plan: title,
          server: pricingPlanServer?.frontmatter.internalName,
        })}
      >
        {i18n('pricingPlanOrderNow', 'Nu bestellen', title)}
      </StyledButton>
      {setShowAllPlanInfo && (
        <StyledButton
          type="button"
          variant="secondary"
          onClick={setShowAllPlanInfo}
        >
          {i18n('pricingplansShowAllPlanInfo', 'Bekijk alle pakketten')}
        </StyledButton>
      )}
    </CtaWrapper>
    {(pricingPlanOptions || []).map(({ included, option }) => option && (
      <CheckList key={option.id}>
        <li>
          {included && <StyledIcon withGradient icon="checkmark" />}
          {!included && <StyledIcon icon="checkmark" />}
          {option.frontmatter.title}
        </li>
      </CheckList>
    ))}
  </article>
);


const StyledButton = styled(Button)`
  width: 100%;
  && {
    padding: 0.65rem 1rem;
  }
`;
const CtaWrapper = styled.div`
  margin: 1rem 0;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.1rem;

  left: 0;
  width: 1rem;
`;

export const CheckList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: .9rem;
  li {
    display: flex;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: .5rem;
  }
`;

const Title = styled.h3`
  padding-top: 1rem;
  font-family: ${(props) => props.theme.fontFamilyBold};
`;
const SubTitle = styled.span`
  display: block;
  padding-bottom: 1rem;
`;
const Table = styled.div`
  /* display: flex;
  flex-direction: column; */
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    &:last-child {
      text-align: right;
      font-family: ${(props) => props.theme.fontFamilyMedium};
      ${(props) => props.largerContent && css`
        font-size: 1.25rem;
      `}
    }
  }
  ${(props) => props.topBorder && css`
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid ${props.theme.gray1};
  `}
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.gray1};
  justify-content: space-between;
  h4 {
    font-size: 1rem;
  }
  ${PreviewCompatibleImage} {
    width: 3rem;
  }
`;

export default styled(PricingPlan)`
  padding: 1rem;
  line-height: 1.5rem;
  background-color: ${(props) => props.theme.white};
`;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useIsNetlifyCMSContext } from '../../context/isNetlifyCMSContext';
import i18n from '../../i18n';
import Container from '../../components/container';
import Button from '../../components/button';
import Title, { Subtitle } from '../../components/title';
import PricingPlan from './pricing-plan';

export const query = graphql`
  fragment PricingPlansBlock on MarkdownRemarkFrontmatterBlocks {
    title
    subtitle
    pricingplans {
      id
      frontmatter {
        ...PricingPlan
      }
    }
    ...ButtonComponent
  }
`;

const References = ({
  className,
  title,
  subtitle,
  pricingplans = [],
  button,
}) => {
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    pricingplans = (pricingplans || []).map((pricingplanId) => ({
      id: pricingplanId,
      frontmatter: {
        title: `[title of ${pricingplanId}]`,
        subtitle: `[subtitle of ${pricingplanId}]`,
        price: `[price of ${pricingplanId}]`,
        setupfee: `[setupfee of ${pricingplanId}]`,

        specs: {
          ram: `[ram of ${pricingplanId}]`,
          cpu: `[cpu of ${pricingplanId}]`,
          storage: `[storage of ${pricingplanId}]`,
          disk: `[disk of ${pricingplanId}]`,
        },

        pricingPlanServer: {
          frontmatter: {
            title: `[title of server of ${pricingplanId}]`,
          },
        },
        pricingPlanOptions: [],

        internalLink: `[internalLink of ${pricingplanId}]`,
        externalLink: `[externalLink of ${pricingplanId}]`,
      },
    }));
  }
  const plans = pricingplans
    .filter(Boolean)
    .filter(({ frontmatter }) => Boolean(frontmatter));

  return (
    <section className={className} id="pricing-plans">
      {title && (
        <Title hasSubtitle={Boolean(subtitle)}>{title}</Title>
      )}
      {subtitle && (
        <Subtitle>{subtitle}</Subtitle>
      )}
      <Container css="margin-top: 2rem;">
        <Wrapper plansCount={plans.length}>
          {plans.map(({ id, frontmatter }) => (
            <StyledPricingPlan key={id} {...frontmatter} css="" />
          ))}
        </Wrapper>
        <TaxAndButton>
          <p>{i18n('pricingplansPricesIncludeTax', 'Prijzen zijn exclusief de BTW')}</p>
          {button?.text && (
            <Button css="margin-top: 1rem" block="pricing-plans" button={button} />
          )}
        </TaxAndButton>
      </Container>
    </section>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: repeat(${(props) => (props.plansCount < 4 ? props.plansCount : 4)}, 1fr);
  }
`;

const StyledPricingPlan = styled(PricingPlan)`
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
`;

const TaxAndButton = styled.div`
  margin-top: 3rem;
  text-align: center;
`;

export default styled(References)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

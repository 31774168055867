import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 174 77.4"
    fill="currentColor"
  >
  <path fill="#312B48" d="M53.7,9.6c-1.7,0-2.8,1.3-2.8,5.1c0,3.6,0.9,5.2,2.8,5.2c2.1,0,2.7-2.2,2.7-5.2C56.3,12,56.2,9.6,53.7,9.6
			 M64,22.1l-7.2,0.1c-0.2,0-0.3-0.1-0.3-0.4v-2h-0.1c-0.8,1.6-1.9,2.7-4.7,2.7c-3.6,0-6.1-3-6-7.9c0-5,2.9-7.9,6.3-7.9
			c2.5,0,3.9,0.8,4.6,2.7h0.1v-5c0-0.3,0-0.7-0.1-1c-0.1-0.5-0.4-0.5-0.8-0.5l-1.7,0c-0.2,0-0.3-0.1-0.3-0.3v-2
			c0-0.3,0.1-0.4,0.3-0.4l7.2-0.1c0.2,0,0.3,0.1,0.3,0.4l-0.1,17c0,0.3,0,0.6,0,0.9c0.1,0.5,0.4,0.5,0.8,0.5c0.3,0,1.4,0.1,1.6,0.1
			c0.3,0,0.3,0.1,0.3,0.3v2.4C64.2,22,64.2,22.1,64,22.1"/>
		<path fill="#312B48" d="M46.8,7c-1.2,0.1-2.2-0.9-2.3-2.1V4.7C44.5,3.8,45.1,3,46,2.7V2.6c-0.4-0.3-0.9-0.5-1.4-0.5
			c-1.6,0-2.8,0.7-2.8,3.2v1.9h2.9c0.2,0,0.3,0.1,0.3,0.4v2c0,0.2-0.1,0.4-0.3,0.4h-2.8v7.4c0,0.3,0,0.6,0.1,0.9
			c0.1,0.5,0.4,0.5,0.8,0.6h1.6c0.2,0,0.3,0.1,0.3,0.3v2.4c0,0.3-0.1,0.4-0.3,0.4h-9.9c-0.2,0-0.3-0.1-0.3-0.4v-2.3
			c0-0.3,0.1-0.3,0.3-0.3s1.1,0,1.4,0c0.5,0,0.7-0.1,0.8-0.6c0.1-0.3,0.1-0.6,0.1-0.9V10h-2.4c-0.2,0-0.3-0.1-0.3-0.4v-2
			c0-0.3,0.1-0.4,0.3-0.4h2.4V6c0-3.1,2.6-6,7-6c3.9,0,5.5,2.5,5.5,4.1C49.3,6,48.1,7,46.8,7"/>
		<path fill="#312B48" d="M69.8,20.3c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-0.9,2.2-2.1V20.3"/>
		<path fill="#312B48" d="M27.7,21.8c0-2.7-1.9-3-1.9-3c-1.5-0.1-3-0.4-4.4-0.9c-1.4-0.7-2.9-1.1-4.4-1.2c-3.2-1.9-6.7-3.3-10.4-4.2
			c0,0-2.2-0.6-1.4,0.4c0.5,0.5,7.1,2.4,9.1,4.8l-0.3,0.1C11.1,16,3.6,13.9,1.4,13.5c0,0-2.7,0-0.5,0.9c1.5,0.6,8.9,3.4,10.3,4.9
			s-1.9,2.9-3.4,2.5c0,0-1,0,0.4,1.4s4.8,0.6,4.8,0.6c0,0.2,0,0.3,0,0.5c0.8,2.9,2.2,5.5,4.2,7.8c0,0-13.7,2.2-14.9,15.6
			c-0.8,9,2.3,18.4,10.2,19c6,0.4,8-3.5,8.3-6.8s-1.4-7.7-6.6-8.1s-6.1,2.9-6.1,2.9s-2.6-14.5,15-12.5l-0.5,6.5
			c2.2,0.4,4.5-0.2,6.2-1.8l0.4-15.2c0,0-0.6-1.8-4.5-3.4c-2.9-1.2-3.7-4.6-1.1-5.1c0.8-0.2,1.6-0.4,2.4-0.3
			C26,22.8,27.7,22.9,27.7,21.8"/>
		<path fill="#312B48" d="M34.7,28.5h38.7l33.1-0.1h28.4c4.6,0,9.3,0,13.9,0h0.2c1.4,0,1.6-2.2,0.2-2.2h-11.4h-27.3h-33H49
			c-4.6,0-9.3,0-13.9,0h-0.2C33.5,26.1,33.3,28.4,34.7,28.5"/>
		<path fill="#312B48" d="M35.3,45.7V38c-0.1-1.7,0.4-3.3,1.6-4.5c1.2-1,2.7-1.5,4.2-1.4c1.6-0.1,3.1,0.4,4.3,1.3
			c1.1,0.8,1.7,2.2,1.7,3.6c0,0.4-0.1,0.7-0.3,1c-0.3,0.2-0.7,0.3-1.1,0.3c-0.9,0-1.4-0.3-1.4-0.9c0-1.6-1.2-2.8-2.8-2.9
			c-0.1,0-0.2,0-0.3,0c-2.1,0-3.1,1.1-3.1,3.4v7.7c0,2.3,1,3.4,3.1,3.4c0.9,0.1,1.7-0.3,2.3-0.8c0.6-0.7,0.9-1.7,0.8-2.6v-2.2h-2.7
			c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.2-0.5-0.2-0.8c0-0.3,0.1-0.5,0.2-0.8c0.1-0.2,0.4-0.4,0.7-0.3h4.6c0.3,0,0.5,0.1,0.7,0.3
			c0.2,0.2,0.3,0.4,0.2,0.7v3.5c0.1,1.7-0.5,3.3-1.6,4.5c-1.2,1-2.8,1.5-4.3,1.4c-1.6,0.1-3.1-0.4-4.3-1.4
			C35.7,49,35.1,47.3,35.3,45.7"/>
		<path fill="#312B48" d="M52.9,44.9h5.3l-2.7-9.1L52.9,44.9z M48.4,50.3c0-0.1,0-0.2,0-0.2l5.3-17.2c0.2-0.6,0.9-1,1.9-1
			s1.7,0.3,1.9,0.9L62.7,50c0,0.1,0,0.2,0,0.2c0,0.4-0.3,0.7-0.6,0.9c-0.4,0.2-0.8,0.4-1.3,0.4c-0.6,0-0.9-0.2-1-0.6l-1.1-3.8h-6.5
			l-1.1,3.8c-0.1,0.4-0.6,0.7-1,0.6c-0.4,0-0.8-0.1-1.2-0.3C48.6,51,48.4,50.7,48.4,50.3"/>
		<path fill="#312B48" d="M63,50.7c0-0.4,0.1-0.7,0.3-1l8.2-15.1h-7.2c-0.3,0-0.5-0.1-0.7-0.4c-0.2-0.3-0.3-0.6-0.2-0.9
			c0-0.3,0.1-0.6,0.2-0.9c0.1-0.2,0.4-0.4,0.7-0.4h9.9c0.6,0,0.9,0.3,0.9,0.8c0,0.4-0.1,0.7-0.3,1L66.6,49h8c0.3,0,0.5,0.1,0.7,0.4
			c0.2,0.3,0.3,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.4,0.4-0.7,0.4H63.9C63.3,51.5,63,51.2,63,50.7"/>
		<path fill="#312B48" d="M77.4,50.6V33.1c0-0.3,0.1-0.5,0.4-0.7c0.3-0.2,0.6-0.3,0.9-0.2h9.4c0.3,0,0.5,0.1,0.7,0.4
			c0.2,0.3,0.2,0.5,0.2,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.4,0.4-0.7,0.4h-7.9v6.1h4c0.3,0,0.5,0.1,0.7,0.3
			c0.2,0.2,0.3,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.3-0.7,0.3h-4V49H88c0.3,0,0.5,0.1,0.7,0.4c0.3,0.5,0.3,1.2,0,1.8
			c-0.1,0.2-0.4,0.4-0.7,0.4h-9.4c-0.3,0-0.6-0.1-0.9-0.3C77.5,51.1,77.3,50.8,77.4,50.6"/>
		<path fill="#312B48" d="M90.8,50.6V33c0-0.3,0.2-0.6,0.4-0.7c0.6-0.3,1.4-0.3,2,0c0.3,0.1,0.4,0.4,0.4,0.7v16h6.9
			c0.3,0,0.5,0.1,0.6,0.4c0.2,0.3,0.2,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.4,0.4-0.6,0.4h-8.5c-0.3,0-0.6-0.1-0.9-0.3
			C91,51.1,90.8,50.8,90.8,50.6"/>
		<path fill="#312B48" d="M103.1,50.6V33.1c0-0.3,0.2-0.6,0.4-0.7c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.7,0.1,1,0.2
			c0.3,0.1,0.4,0.4,0.4,0.7v16h6.9c0.3,0,0.5,0.1,0.6,0.4c0.3,0.5,0.3,1.2,0,1.8c-0.1,0.2-0.4,0.4-0.6,0.4h-8.5
			c-0.3,0-0.6-0.1-0.9-0.3C103.3,51.1,103.1,50.9,103.1,50.6"/>
		<path fill="#312B48" d="M115.3,50.6V33.1c0-0.3,0.1-0.5,0.4-0.7c0.3-0.2,0.6-0.3,0.9-0.2h9.4c0.3,0,0.5,0.1,0.7,0.4
			c0.2,0.3,0.2,0.6,0.2,0.8c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.2-0.4,0.4-0.7,0.4h-7.9v6.1h4.1c0.3,0,0.5,0.1,0.7,0.3
			c0.2,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.8c-0.2,0.2-0.4,0.3-0.7,0.3h-4.1v6.1h7.9c0.3,0,0.5,0.1,0.7,0.4
			c0.2,0.3,0.3,0.6,0.2,0.9c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.4,0.4-0.7,0.4h-9.4c-0.3,0-0.6-0.1-0.9-0.3
			C115.5,51.2,115.3,50.9,115.3,50.6"/>
		<path fill="#312B48" d="M128.8,50.7V33.1c0-0.6,0.4-0.9,1.4-0.9c0.4,0,0.8,0.1,1.2,0.3c0.4,0.4,0.7,0.8,0.9,1.3l5.9,11.5V33.1
			c0-0.3,0.2-0.5,0.4-0.7c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.7,0.1,1,0.2c0.3,0.1,0.4,0.4,0.4,0.7v17.5c0,0.3-0.2,0.5-0.4,0.7
			c-0.3,0.2-0.7,0.3-1,0.2c-0.7,0-1.4-0.4-1.7-1l-6.3-12.1v12.1c0,0.3-0.2,0.5-0.4,0.7c-0.6,0.4-1.4,0.4-2,0
			C128.9,51.2,128.8,50.9,128.8,50.7"/>
		<path fill="#312B48" d="M30,57.3h104.9c4.8,0,9.7,0.1,14.5,0h0.2c1.4,0,1.7-2.2,0.2-2.2H45c-4.8,0-9.7,0-14.5,0h-0.2
			C28.8,55.1,28.6,57.3,30,57.3"/>
<path fill="#312B48" d="M34,75.7v-2.1c0-0.7,0.3-1.3,0.7-1.9c0.5-0.6,1.1-1.2,1.7-1.7l2.1-1.5c0.7-0.5,1.2-1,1.8-1.7
	c0.4-0.5,0.7-1.1,0.7-1.8c0-0.6-0.2-1.1-0.6-1.6c-0.4-0.5-1.1-0.7-1.7-0.7c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.4-0.6,1-0.6,1.6
	c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.2-0.6,0.3-0.9,0.3c-0.7,0-1-0.4-1-1.3c0-1.1,0.5-2.1,1.3-2.7c1.9-1.5,4.5-1.4,6.3,0.1
	c0.9,0.8,1.4,1.9,1.3,3.1c0,0.9-0.3,1.7-0.7,2.4c-0.5,0.7-1,1.4-1.7,1.9c-0.7,0.5-1.3,1-2,1.5c-0.6,0.4-1.2,0.9-1.7,1.4
	c-0.4,0.3-0.7,0.8-0.7,1.4v0.8h6.3c0.2,0,0.4,0.1,0.5,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
	H35c-0.2,0-0.5-0.1-0.7-0.2C34.1,76.1,34,75.9,34,75.7z"/>
<path fill="#312B48" d="M45.1,71.8v-6.2c-0.1-1.3,0.4-2.7,1.3-3.6c1-0.8,2.2-1.2,3.5-1.2c1.3-0.1,2.5,0.3,3.5,1.2
	c0.9,1,1.4,2.3,1.3,3.6v6.2c0.1,1.3-0.4,2.7-1.3,3.6c-1,0.8-2.3,1.2-3.5,1.2c-1.3,0.1-2.5-0.3-3.5-1.2C45.4,74.4,45,73.1,45.1,71.8z
	 M47.3,71.8c0,1.8,0.9,2.8,2.6,2.8c0.7,0,1.4-0.2,1.9-0.7c0.5-0.6,0.7-1.3,0.7-2.1v-6.2c0.1-0.8-0.2-1.5-0.7-2.1
	c-0.5-0.5-1.2-0.7-1.9-0.7c-1.7,0-2.6,0.9-2.6,2.8V71.8z M49.2,69.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.5,0.3-0.6
	c0.2-0.2,0.4-0.3,0.7-0.2c0.2,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.2
	C49.7,69.6,49.4,69.5,49.2,69.3z"/>
<path fill="#312B48" d="M96.6,75.7v-2.1c0-0.7,0.3-1.3,0.7-1.9c0.5-0.6,1.1-1.2,1.8-1.7l2-1.5c0.7-0.5,1.2-1,1.8-1.7
	c0.4-0.5,0.7-1.1,0.7-1.8c0-0.6-0.2-1.1-0.6-1.6c-0.4-0.5-1.1-0.7-1.7-0.7c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.4-0.6,1-0.6,1.6
	c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.2-0.6,0.3-0.9,0.3c-0.7,0-1-0.4-1-1.3c0-1.1,0.5-2.1,1.3-2.7c1.9-1.5,4.5-1.4,6.3,0.1
	c0.9,0.8,1.4,1.9,1.3,3.1c0,0.9-0.3,1.7-0.7,2.4c-0.5,0.7-1,1.4-1.7,1.9c-0.7,0.5-1.3,1-2,1.5c-0.6,0.4-1.2,0.9-1.7,1.4
	c-0.4,0.3-0.7,0.8-0.7,1.4v0.8h6.3c0.2,0,0.4,0.1,0.5,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
	h-7.6c-0.2,0-0.5-0.1-0.7-0.2C96.7,76.1,96.6,75.9,96.6,75.7z"/>
<path fill="#312B48" d="M107.7,71.8v-6.2c-0.1-1.3,0.4-2.7,1.3-3.6c2.1-1.5,5-1.5,7.1,0c0.9,1,1.4,2.3,1.3,3.6v6.2
	c0.1,1.3-0.4,2.7-1.3,3.6c-1,0.8-2.3,1.2-3.5,1.2c-1.3,0.1-2.5-0.4-3.5-1.2C108.1,74.5,107.6,73.1,107.7,71.8z M109.9,71.8
	c0,1.8,0.9,2.8,2.6,2.8c0.7,0,1.4-0.2,1.9-0.7c0.5-0.6,0.7-1.3,0.7-2.1v-6.2c0.1-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.2-0.7-1.9-0.7
	c-1.7,0-2.6,0.9-2.6,2.8V71.8z M111.8,69.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.2,0.1-0.5,0.3-0.6c0.2-0.2,0.4-0.3,0.7-0.2
	c0.2,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.2C112.3,69.6,112,69.5,111.8,69.3z"
	/>
<path fill="#312B48" d="M119.8,75.7v-2.1c0-0.7,0.3-1.3,0.7-1.9c0.5-0.6,1.1-1.2,1.7-1.7l2.1-1.5c0.7-0.5,1.3-1,1.8-1.7
	c0.4-0.5,0.7-1.1,0.7-1.8c0-0.6-0.2-1.1-0.6-1.6c-0.4-0.5-1.1-0.7-1.7-0.7c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.4-0.6,1-0.6,1.6
	c0,0.2-0.1,0.5-0.3,0.6c-0.3,0.2-0.6,0.3-0.9,0.3c-0.7,0-1-0.4-1-1.3c0-1.1,0.5-2.1,1.3-2.7c1.9-1.5,4.5-1.4,6.3,0.1
	c0.9,0.8,1.4,1.9,1.3,3.1c0,0.9-0.3,1.7-0.7,2.4c-0.5,0.7-1.1,1.4-1.7,1.9c-0.7,0.5-1.3,1-2,1.5c-0.6,0.4-1.2,0.9-1.7,1.4
	c-0.4,0.3-0.7,0.8-0.7,1.4v0.8h6.3c0.2,0,0.4,0.1,0.5,0.3c0.2,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
	h-7.6c-0.2,0-0.5-0.1-0.7-0.2C119.9,76.1,119.8,75.9,119.8,75.7z"/>
<path fill="#312B48" d="M130.9,71.8v-6.2c-0.1-1.3,0.4-2.7,1.3-3.6c2.1-1.5,5-1.5,7.1,0c0.9,1,1.4,2.3,1.3,3.6v6.2
	c0.1,1.3-0.4,2.7-1.3,3.6c-1,0.8-2.3,1.2-3.5,1.2c-1.3,0.1-2.5-0.3-3.5-1.2C131.2,74.4,130.8,73.1,130.9,71.8z M133.1,71.8
	c0,1.8,0.9,2.8,2.6,2.8c0.7,0,1.4-0.2,1.9-0.7c0.5-0.6,0.7-1.3,0.7-2.1v-6.2c0.1-0.8-0.2-1.5-0.7-2.1c-0.5-0.5-1.2-0.7-1.9-0.7
	c-1.7,0-2.6,0.9-2.6,2.8V71.8z M135,69.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.2
	c0.2,0,0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.2C135.5,69.6,135.2,69.5,135,69.3z"
	/>
<path fill="#312B48" d="M56,64c0-0.4,0.2-0.7,0.5-0.9l2.2-2.1c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.5,0.1,0.7,0.2
	c0.2,0.1,0.3,0.3,0.3,0.5v14.2c0,0.2-0.1,0.4-0.3,0.5c-0.5,0.3-1.1,0.3-1.6,0c-0.2-0.1-0.3-0.3-0.3-0.5V63.8l-0.7,0.9
	c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.3C56.1,64.5,56,64.2,56,64z"/>
<path fill="#312B48" d="M62.5,72.8c0-0.3,0.1-0.5,0.2-0.7c0.2-0.2,0.5-0.2,0.8-0.2c0.8,0,1.2,0.3,1.2,0.9c0,0.5,0.3,1,0.7,1.3
	c0.5,0.4,1.2,0.5,1.8,0.5c1.7,0,2.6-0.9,2.6-2.8v-2.7c-0.7,0.9-1.9,1.3-3,1.2c-2.9,0-4.4-1.4-4.4-4.4v-0.4c-0.1-1.3,0.4-2.6,1.3-3.6
	c1-0.8,2.2-1.2,3.5-1.2c1.3-0.1,2.5,0.3,3.5,1.2c0.9,1,1.4,2.3,1.3,3.6v6.2c0.1,1.3-0.4,2.7-1.3,3.6c-1,0.8-2.3,1.2-3.6,1.2
	c-1.3,0.1-2.5-0.3-3.5-1.2C62.9,74.8,62.5,73.8,62.5,72.8z M64.7,65.8c0,1.8,0.8,2.7,2.5,2.7c1.2,0.2,2.3-0.6,2.5-1.8
	c0-0.2,0-0.4,0-0.6v-0.6c0-1.8-0.8-2.7-2.6-2.7s-2.5,0.9-2.5,2.7L64.7,65.8z"/>
<path fill="#312B48" d="M83,76.4c-0.7,0-1.4-0.1-2-0.3c-0.6-0.2-1.1-0.5-1.6-0.8c-0.9-0.7-1.4-1.8-1.4-2.9c0-0.8,0.2-1.6,0.7-2.2
	c0.5-0.7,1.1-1.3,1.8-1.6c-0.4-0.5-0.8-1-1.1-1.6c-0.2-0.5-0.3-1-0.3-1.5c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.5-0.9,0.8-1.2
	c0.4-0.3,0.8-0.6,1.3-0.8c1-0.4,2.2-0.4,3.2,0c0.5,0.2,0.9,0.4,1.2,0.7c0.7,0.6,1.1,1.5,1.1,2.5c0,1.5-0.9,2.7-2.6,3.8l2.6,2.6
	c0.2-0.5,0.4-1,0.6-1.5c0.2-0.6,0.2-1.2,0.3-1.8l2.5,0.2c-0.1,0.9-0.3,1.8-0.6,2.6c-0.2,0.8-0.6,1.5-1,2.2l2.6,2.7h-3.5L87,75.1
	c-0.6,0.4-1.2,0.8-1.8,1C84.4,76.3,83.7,76.4,83,76.4z M80.8,72.1c0,0.4,0.1,0.7,0.3,1c0.2,0.3,0.5,0.5,0.9,0.7
	c0.4,0.2,0.8,0.3,1.3,0.2c0.7,0,1.4-0.2,2-0.6L82,70.1c-0.4,0.2-0.7,0.5-0.9,0.8C80.9,71.3,80.8,71.7,80.8,72.1z M81.7,65.3
	c0,0.3,0.1,0.6,0.3,0.9c0.2,0.4,0.5,0.8,0.9,1.1c0.5-0.2,0.9-0.5,1.2-0.9c0.3-0.3,0.4-0.7,0.4-1.2c0-0.4-0.1-0.7-0.4-0.9
	c-0.3-0.3-0.6-0.4-1-0.4c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.5,0.5C81.7,64.8,81.7,65.1,81.7,65.3z"/>
<circle fill="#FB7C11" cx="149.9" cy="30.4" r="14.2"/>
<path fill="#FFFFFF" d="M140.7,36.7v-1.8l1.2-1c0.4-0.3,0.7-0.6,0.9-0.8s0.5-0.5,0.7-0.7l0.6-0.6c0.4-0.4,0.8-0.8,1.2-1.3
	c0.3-0.3,0.5-0.7,0.7-1.1c0.1-0.3,0.2-0.7,0.2-1c0-0.5-0.1-0.9-0.4-1.3c-0.3-0.3-0.7-0.5-1.1-0.5c-0.4,0-0.8,0.2-1.1,0.4
	c-0.3,0.3-0.6,0.7-0.6,1.2l-2.3-0.4c0.1-0.7,0.4-1.3,0.8-1.8c0.4-0.5,0.9-0.9,1.5-1.2c0.6-0.3,1.3-0.4,1.9-0.4
	c0.6,0,1.1,0.1,1.6,0.3c0.9,0.4,1.7,1.1,2.1,2c0.2,0.5,0.3,1,0.3,1.5c0,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.5,1-0.9,1.4
	c-0.5,0.6-1,1.1-1.5,1.7c-0.3,0.3-0.6,0.5-0.9,0.8l-0.9,0.8h4.7v2.2L140.7,36.7z"/>
<path fill="#FFFFFF" d="M155.2,36.9c-0.6,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.1-0.6-1.5-1c-0.4-0.4-0.8-1-1-1.5c-0.2-0.6-0.4-1.3-0.4-2
	c0-0.7,0.1-1.3,0.4-1.9c0.2-0.6,0.6-1.1,1-1.5c0.4-0.4,0.9-0.8,1.4-1c0.6-0.2,1.2-0.4,1.8-0.4c0.6,0,1.2,0.1,1.8,0.4
	c0.5,0.2,1,0.6,1.4,1c0.4,0.4,0.7,1,0.9,1.5c0.2,0.6,0.3,1.3,0.3,2v0.6H153c0.1,0.6,0.3,1.2,0.7,1.6c0.4,0.4,0.9,0.6,1.5,0.6
	c0.4,0,0.8-0.1,1.1-0.3c0.4-0.2,0.6-0.5,0.8-0.8l2.2,0.7c-0.2,0.5-0.5,1-0.9,1.3c-0.4,0.4-0.9,0.7-1.4,0.9
	C156.4,36.9,155.8,37,155.2,36.9z M155.1,29.1c-0.5,0-1,0.2-1.4,0.5c-0.4,0.4-0.6,0.9-0.7,1.4h4c-0.1-0.5-0.3-1-0.7-1.4
	C156,29.3,155.5,29.1,155.1,29.1L155.1,29.1z"/>
  </svg>
);

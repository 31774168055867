import React from 'react';
import styled, { css } from 'styled-components';

const DefaultInput = ({ children, className }) => <div className={className}>{children}</div>;

const formElementActive = css`
  input {
    outline: 0;
  }
  label {
    font-size: .6rem;
    top: -1rem;
    left: 0;
  }
`;

export default styled(DefaultInput)`
  position: relative;
  margin-top: ${({ theme }) => theme.formGap};
  flex: 1 0 100%;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    flex: 1 0 calc(50% - ${({ theme }) => theme.formGap});
    ${({ template }) => template === 'columns' && css`
      &:nth-child(2n+0) {
        margin-left: ${({ theme }) => theme.formGap};
      }
    `}
  }

  input, select {
    box-sizing: border-box;
    padding-left: .5rem;
    border: none;
    background-color: ${(props) => props.theme.gray1};
    color: ${(props) => props.theme.fontColor};
    height: 3rem;
    width: 100%;
  }

  label {
    position: absolute;
    top: 1rem;
    left: .5rem;
    color: ${(props) => props.theme.fontColorLight};
    font-family: ${(props) => props.theme.fontFamilyNormal};
    pointer-events: none;
    transition: all .2s ease 0s;
  }

  &:focus-within {
    ${formElementActive}
    input {
      border-bottom-color: ${(props) => props.theme.secondaryColor};
    }
  }
  ${(props) => props.value && css`
    ${formElementActive}
  `}
`;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../layout/icons';
import Content from '../../components/content';

const Faq = ({ faq, className }) => {
  const [menuIsOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuIsOpen);

  return (
    <article className={className}>
      <Heading menuIsOpen={menuIsOpen} onClick={toggleMenuOpen}>
        <h3 css={menuIsOpen ? "font-family: 'Airbnb Cereal App Medium';" : ''}>{faq.question}</h3>
        <>
          {!menuIsOpen && (
            <Icon icon="chevron-down" />
          )}
          {menuIsOpen && (
            <Icon icon="chevron-up" />
          )}
        </>
      </Heading>
      {menuIsOpen && (
        <Content css="padding: 1rem;" content={faq.answer} markdown />
      )}
    </article>
  );
};


const Heading = styled.button.attrs({ type: 'button' })`
  background: unset;
  border: unset;
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.gray2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem;
  text-align: left;
  ${Icon} {
    width: 1.5rem;
    flex-shrink: 0;
    margin-left: 1rem;
  }
  ${(props) => props.menuIsOpen && css`
    background-color: ${props.theme.gray1};
  `}
`;

export default styled(Faq)``;

import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Content from '../../components/content';
import Container from '../../components/container';
import Button from '../../components/button';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Blob from '../../components/blobs';

export const query = graphql`
  fragment TextCasesBlock on MarkdownRemarkFrontmatterBlocks {
    content
    ...ButtonComponents
    casesButton {
      variant
      internalLink {
        frontmatter {
          slug
        }
      }
      externalLink
      text
      targetBlank
      id
    }
    casesTitle
    cases {
      id
      frontmatter {
        name
        externalLink
        internalLink {
          frontmatter {
            slug
          }
        }
        linkText
        textCasesBlockcaseLogo: caseLogo {
          publicURL
          childImageSharp {
            fluid(maxWidth: 450, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

const TextCases = (props) => {
  const {
    content,
    buttons,
    casesTitle,
    cases,
    casesButton,
    className,
  } = props;
  return (
    <section className={className}>
      <Container>
        <BlobWrapper>
          <Blob mirror block="text-cases" color="gray1" />
        </BlobWrapper>
        <Wrapper>
          <div>
            <Content
              content={content}
              markdown
              block="text-cases"
              buttons={buttons}
            />
          </div>
          <div
            css={casesButton?.text && css`
              display: flex;
              flex-direction: column;
            `}
          >
            {casesTitle && (
              <CasesTitle dangerouslySetInnerHTML={{ __html: casesTitle }} />
            )}
            <Cases>
              {(cases || []).filter(Boolean).map((node) => {
                // case will be a relationship string in NetlifyCMS preview
                const singleCase = node?.frontmatter ?? {
                  name: `[name of ${node}]`,
                  externalLink: node,
                  linkText: `[link to ${node}]`,
                };
                return (
                  <Case
                    key={`key-${singleCase.name}`}
                    // eslint-disable-next-line max-len
                    imageWidth={singleCase.textCasesBlockcaseLogo?.childImageSharp?.fluid?.presentationWidth}
                  >
                    <div>
                      <PreviewCompatibleImage
                        image={singleCase.textCasesBlockcaseLogo}
                        alt={singleCase.name}
                      />
                    </div>
                  </Case>
                );
              })}
            </Cases>
            {casesButton?.text && (
              <div
                css={`
                  flex-grow: 1;
                  display: flex;
                  align-items: flex-end;
                `}
              >
                <Button
                  block="text-cases"
                  button={casesButton}
                  variant={casesButton?.variant}
                />
              </div>
            )}
          </div>
        </Wrapper>
      </Container>
    </section>
  );
};

const CasesTitle = styled.h2`
  margin-bottom: 2rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-top: .5rem;
  }
  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;
const Case = styled.div`
  ${PreviewCompatibleImage} {
    margin: 0 auto;
    max-width: calc(${(props) => props.imageWidth}px / 1.5) !important;
  }
`;

const Cases = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    max-width: 20rem;
  }
`;
const Wrapper = styled.section`
  position: relative;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
  ${Button} {
    margin-top: ${(props) => props.theme.grid.columnGap};
  }
`;
const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    align-items: initial;
    justify-content: flex-end;
  }
  ${Blob} {
    height: 50%;
    margin-right: -5rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      height: 100%;
    }
  }
`;
export default styled(TextCases)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
  ${Container} {
    padding-top: 3rem;
    padding-bottom: 3rem;
    position: relative;
  }
`;

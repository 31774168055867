import React from 'react';
import styled from 'styled-components';
import LoadingIndicatorSVG from '../../components/loading-indicator';
import i18n from '../../i18n';

const Loader = ({ children }) => (
  <Wrapper>
    <div>
      <LoadingIndicatorSVG />
      <h3>{i18n('resultsLoader', 'Resultaten ophalen...')}</h3>
      {children && (
        <ChildrenWrapper>
          {children}
        </ChildrenWrapper>
      )}
    </div>
  </Wrapper>
);

Loader.defaultProps = {
  children: null,
};

const ChildrenWrapper = styled.div`
  margin-top: 1rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50rem;
  text-align: center;
`;

export default Loader;

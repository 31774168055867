import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Text from './text';
import TextImage from './text-image';
import TextContact from './text-contact';
import Image from './image';
import Hero from './hero';
import Video from './video';
import Todo from './todo';
import Static from './static-block';
import IconBlobText from './icon-blob-text';
import Quote from './quote';
import Form from './form';
import Case from './case';
import Cases from './cases';
import Percentages from './percentages';
import IconTextList from './icon-text-list';
import References from './references';
import Faqs from './faqs';
import Features from './features';
import FeaturesLists from './features-lists';
import FeaturePreviews from './feature-previews';
import Slas from './slas';
import Support from './support';
import PricingPlans from './pricing-plans';
import FormMaps from './form-maps';
import TextList from './text-list';
import TextCasesBlock from './text-cases';
import PerformanceScan from './performance-scan';
// import PerformanceScanTop10 from './performance-scan-top-10';
// import PerformanceScanBars from './performance-scan-bars';
import PerformanceMetrics from './performance-metrics';
import Icon from '../layout/icons';
import PerformanceScanCompare from './performance-scan-compare';

const blockMapping = {
  hero: Hero,
  case: Case,
  cases: Cases,
  text: Text,
  'text-image': TextImage,
  'text-contact': TextContact,
  'icon-blob-text': IconBlobText,
  'icon-text-list': IconTextList,
  'text-cases': TextCasesBlock,
  image: Image,
  video: Video,
  quote: Quote,
  form: Form,
  todo: Todo,
  static: Static,
  percentages: Percentages,
  references: References,
  features: Features,
  'features-lists': FeaturesLists,
  'feature-previews': FeaturePreviews,
  faqs: Faqs,
  slas: Slas,
  support: Support,
  'pricing-plans': PricingPlans,
  'form-maps': FormMaps,
  textList: TextList,
  'performance-scan': PerformanceScan,
  'performance-scan-compare': PerformanceScanCompare,
  // 'performance-scan-10': PerformanceScanTop10,
  // 'performance-scan-bars': PerformanceScanBars,
  'performance-metrics': PerformanceMetrics,
};

export const query = graphql`
  fragment BlocksMapping on MarkdownRemarkFrontmatter {
    blocks {
      type
      ...TextBlock
      ...TextImageBlock
      ...TextContactBlock
      ...ImageBlock
      ...HeroBlock
      ...VideoBlock
      ...StaticBlock
      ...IconBlobTextBlock
      ...QuoteBlock
      ...FormBlock
      ...CaseBlock
      ...CasesBlock
      ...PercentagesBlock
      ...IconTextListBlock
      ...ReferencesBlock
      ...FeaturesBlock
      ...FeaturesListsBlock
      ...FeaturePreviewsBlock
      ...FaqsBlock
      ...SlasBlock
      ...SupportBlock
      ...PricingPlansBlock
      ...FormMapsBlock
      ...TextListBlock
      ...TextCasesBlock
      ...PerformanceScan
    }
  }
`;

const Blocks = ({
  blocks, size = 3, children, className,
}) => {
  if ((blocks || []).length === 0) {
    return 'No blocks were given!';
  }

  return (
    <div style={{ flex: size }} className={className}>
      {children}
      {blocks.map((block, i) => {
        const Component = blockMapping[block.type] || 'div';
        return (
          <div key={`${block.type}-${i}`}>
            <Component {...block} />
          </div>
        );
      })}

    </div>
  );
};

export const StyledIcon = styled(Icon)`
  color: white;
  height: ${(props) => props.height || '.7rem'};
`;

const BlocksMapping = styled(Blocks)`
  min-width: 300px;
`;

export default BlocksMapping;

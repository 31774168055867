import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import Container from '../../../../components/container';
import Switch from '../pricingplans/switch';
import AllPlans from '../pricingplans/all-plans';


const Plans = styled.div`
  background-color: ${(props) => props.theme.gray1};
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const PricingPlanGroup = ({ className, edges }) => {
  const [switchIsProduction, setIsProductionSwitch] = useState(true);
  const buttonEl = useRef(null);

  const plans = edges
    .filter(({ node }) => (switchIsProduction
      ? node.frontmatter.developmentOrProduction === 'production'
      : node.frontmatter.developmentOrProduction === 'development'
    ))
    .sort((a, b) => (a.node.frontmatter.price > b.node.frontmatter.price ? 1 : -1));
  return (
    <div className={className}>
      <Plans>
        <Container>
          <Switch
            toggleSwitch={() => setIsProductionSwitch(!switchIsProduction)}
            switched={switchIsProduction}
          />
        </Container>
        <AllPlans ref={buttonEl} plans={plans} />
      </Plans>
    </div>
  );
};

export default styled(PricingPlanGroup)``;

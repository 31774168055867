import React from 'react';
import styled from 'styled-components';
import LoadingIndicatorSVG from '../../components/loading-indicator';

const Loader = () => (
  <Wrapper>
    <div>
      <LoadingIndicatorSVG />
    </div>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  text-align: center;
`;

export default Loader;

import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  to {
    max-height: auto;
  }
`;

export default styled((props) => (
  <div className={props.className}>{props.children}</div>
))`
  border-radius: ${(props) => props.theme.borderRadius};
  padding: .5rem;
  margin-top: 1rem;
  background-color: ${(props) => props.theme.alerts[props.variant]?.backgroundColor};
  color: ${(props) => props.theme.alerts[props.variant]?.color};
  border: 1px solid ${(props) => props.theme.alerts[props.variant]?.borderColor};
  animation: ${fadeIn} .2s;
  overflow: hidden;
  text-align: center;
`;

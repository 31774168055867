import React from 'react';
import { graphql } from 'gatsby';
import PricingPlans from './blocks/pricingplans';
import ContactTextWithBlob from './blocks/contact-text-with-blob';
import AllPlans from './blocks/all-plans'

export const blockMapping = {
  pricingplans: PricingPlans,
  'contact-text-with-blob': ContactTextWithBlob,
  'all-plans': AllPlans,
};

export const query = graphql`
  fragment StaticBlock on MarkdownRemarkFrontmatterBlocks {
    title
    block
  }
`;

const Static = ({ block, ...props }) => {
  const Component = blockMapping[block] || 'div';
  return <Component {...props} />;
};

export default Static;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Title from '../../components/title';
import Icon from '../../layout/icons';
import Sla from './sla';

export const query = graphql`
  fragment SlasBlock on MarkdownRemarkFrontmatterBlocks {
    title
    slasUSPs
    slas {
      id
      frontmatter {
        ...Sla
      }
    }
  }
`;

const Slas = ({
  title,
  slasUSPs = [],
  slas,
  className,
}) => (
  <section className={className}>
    <Container>
      {title && (
        <Title>{title}</Title>
      )}
      {slasUSPs.length > 0 && (
        <USPsWrapper>
          {slasUSPs.map((usp, index) => (
            <div key={`${usp}-${index}`}>
              <StyledIcon icon="checkmark" withGradient />
              <div>{usp}</div>
            </div>
          ))}
        </USPsWrapper>
      )}
      <SlasWrapper>
        {(slas || []).map((node, index) => {
          // sla will be a relationship string in NetlifyCMS preview
          const sla = node?.frontmatter ?? {
            title: `[name of ${node}]`,
            text: `[text of ${node}]`,
            label: 'This is the label',
            slaImage: 'https://via.placeholder.com/64x64?text=Sla+image',
            slaSpecs: [{
              key: 'Example',
              value: 'value',
            }],
          };

          return (
            <Sla key={`${index}-${sla.name}`} {...sla} />
          );
        })}
      </SlasWrapper>
    </Container>
  </section>
);

const SlasWrapper = styled.div`
`;

const USPsWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  padding: 0 1rem;
  margin: 2rem 0;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 8rem;
  }
  > * {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }
`;


const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0.1rem;

  left: 0;
  width: 1rem;
`;

export default styled(Slas)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

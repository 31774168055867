import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Container from '../../components/container';
import Content from '../../components/content';
import Icon from '../../layout/icons';
import Button from '../../components/button';
import PreviewCompatibleImage from '../../components/preview-compatible-image';

export const query = graphql`
  fragment IconTextListBlock on MarkdownRemarkFrontmatterBlocks {
    template
    columnsCount
    iconTextItems {
      icon
      text
      title
      iconTextListImage {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            presentationWidth
          }
        }
      }
      ...ButtonComponent
    }
  }
`;

const TextOverBlockItem = ({ item }) => (
  <div
    css={`
      background: linear-gradient(${(props) => props.theme.gray1}, ${(props) => props.theme.gray1});
      background-repeat: no-repeat;
      /* 2.722rem is an estimation of the button height. */
      background-size: 75% calc(100% - 2.722rem);
      padding: 2rem 0 0 2rem;
      p {
        color: ${(props) => props.theme.fontColorLight};
        margin-bottom: 2rem;
      }
    `}
  >
    <Content>
      <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
      <p>{item.text}</p>
      {item.button?.text && (
        <Button block="icon-text-list" button={item.button} />
      )}
    </Content>
  </div>
);

const IconTextItem = ({ item, direction = 'row' }) => (
  <div css={css`display: flex; flex-direction: ${direction};`}>
    <div>
      <IconWrapper large>
        {item.icon && item.icon !== '' && (
          <Icon icon={item.icon} />
        )}
        {item.iconTextListImage && (
          <PreviewCompatibleImage image={item.iconTextListImage} alt={item.title} />
        )}
      </IconWrapper>
    </div>
    <Content css="position: relative">
      <ContentTitle dangerouslySetInnerHTML={{ __html: item.title }} />
      <p>{item.text}</p>
      {item.button?.text && (
        <Button block="icon-text-list" button={item.button} />
      )}
    </Content>
  </div>
);


const DefaultItem = ({ item }) => (
  <Content compact>
    <div css="display: flex; align-items: center; margin-bottom: 0.5rem;">
      <div>
        {item.icon && item.icon !== '' && (
          <Icon icon={item.icon} css="width: 2rem; margin-right: 1rem" />
        )}
        {item.iconTextListImage && (
          <PreviewCompatibleImage css="width: 2rem; margin-right: 1rem" image={item.iconTextListImage} alt={item.title} />
        )}
      </div>
      <h5 dangerouslySetInnerHTML={{ __html: item.title }} />
    </div>
    <p>{item.text}</p>
    {item.button?.text && (
      <Button block="icon-text-list" button={item.button} />
    )}
  </Content>
);

const IconTextList = ({
  className,
  template,
  columnsCount,
  iconTextItems = [],
}) => (
  <section className={className}>
    <Container>
      <Wrapper columns={columnsCount} itemsCount={iconTextItems.length} template={template}>
        {iconTextItems.map((item, index) => {
          if (template === 'text-over-block') {
            return <TextOverBlockItem key={`${item}-${index}`} item={item} />;
          }
          if (template === 'icon-text') {
            return <IconTextItem key={`${item}-${index}`} item={item} />;
          }
          if (template === 'icon-above-text' || template === 'icon-above-text-small') {
            return <IconTextItem key={`${item}-${index}`} item={item} direction="column" />;
          }
          return <DefaultItem key={`${item}-${index}`} item={item} />;
        })}
      </Wrapper>
    </Container>
  </section>
);

const IconWrapper = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  margin-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => (props.large ? css`
    width: 5rem;
    height: 5rem;
    @media all and (min-width: ${props.theme.breakingpoints.xl}) {
      margin-left: -0.9rem;
    }
  ` : '')}

  ${Icon} {
    position: relative;
    z-index: ${(props) => props.theme.zIndex.iconTextListBlockIcon};
    width: 2rem;
    color: ${(props) => props.theme.primaryColor};
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: repeat(${(props) => props.columns || 2}, 1fr);
  }

  ${(props) => props.template === 'default' && css`
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 2rem;
  `}

  ${(props) =>
    props.template === "icon-above-text-small" &&
    css`
      margin-left: -1rem;
      margin-right: -1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        position: relative;
        top: -240px;
        left: 160px;
        z-index: -1;
        width: 66.6666666667%;
        margin: 0 auto;
        padding: 6rem 2rem 10rem;
        margin-bottom: -8rem;

        > div {
          position: relative;
          top: 100px;
          left: -160px;
          z-index: 1;
        }
      }
      background: ${props.theme.pastelPink};
    `}
`;

const ContentTitle = styled.h5`
  && { font-family: ${(props) => props.theme.fontFamilyBold}; }
  padding-bottom: 1.2rem;
  padding-top: 1.2rem;
`

export default styled(IconTextList)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

import React from 'react';
import styled, { css } from 'styled-components';
import Content from './content';

import i18n from '../i18n';

const CircularChart = ({ percentage }) => (
  <Circle viewBox="0 0 36 36">
    <path
      className="circle"
      strokeDasharray={`${percentage}, 100`}
      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
    />
  </Circle>
);

const Circle = styled.svg`
  width: 3rem;
  margin-right: 1rem;
  &:not(:root) {
    overflow: hidden;
  }
  .circle {
    fill: none;
    stroke: ${(props) => props.theme.primaryColor};
    stroke-width: 2.8;
    transition: stroke-dasharray 1s ease-in-out;
  }

  /*
  this is for animation
  .circle.not-active{
    stroke-dasharray:0 100
  }
  */
`;

const PerformanceCounter = ({ percentages, className, variant }) => {
  if (!percentages) {
    return null;
  }

  return (
    <div className={className}>
      <Wrapper variant={variant}>
        <div>
          <Content>
            <Title>{i18n('performanceCounterGain', 'Performance winst')}</Title>
            <ChartWrapper variant={variant}>
              <CircularChart percentage={percentages.performancePercentages} />
              <div>
                <div><Percentage>{percentages.performancePercentages}%</Percentage> {i18n('performanceCounterFaster', 'sneller')}</div>
                <div><Seconds>{percentages.performanceSeconds} sec</Seconds> {i18n('performanceCounterFaster', 'sneller')}</div>
              </div>
            </ChartWrapper>
          </Content>
        </div>

        <div>
          <Content>
            <Title>{i18n('performanceCounterCheckoutImprovement', 'Verbetering checkout')}</Title>
            <ChartWrapper variant={variant}>
              <CircularChart percentage={percentages.checkoutPercentages} />
              <div>
                <div><Percentage>{percentages.checkoutPercentages}%</Percentage> {i18n('performanceCounterFaster', 'sneller')}</div>
                <div><Seconds>{percentages.checkoutSeconds} sec</Seconds> {i18n('performanceCounterFaster', 'sneller')}</div>
              </div>
            </ChartWrapper>
          </Content>
        </div>

        <div>
          <Content>
            <Title>{i18n('performanceCounterFilteringImprovement', 'Verbetering filtering')}</Title>
            <ChartWrapper variant={variant}>
              <CircularChart percentage={percentages.filteringPercentages} />
              <div>
                <div><Percentage>{percentages.filteringPercentages}%</Percentage> {i18n('performanceCounterFaster', 'sneller')}</div>
                <div><Seconds>{percentages.filteringSeconds} sec</Seconds> {i18n('performanceCounterFaster', 'sneller')}</div>
              </div>
            </ChartWrapper>
          </Content>
        </div>
      </Wrapper>
    </div>
  );
};

const Title = styled.h5`
  margin: 1rem 0 .5rem 0;
`;

const Percentage = styled.span`
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.fontFamilyMedium};
  font-size: 1.2rem;
`;

const Seconds = styled.span`
  color: ${(props) => props.theme.secondaryColor};
  font-family: ${(props) => props.theme.fontFamilyMedium};
`;

const ChartWrapper = styled.div`
  display: flex;
  ${(props) => props.variant === 'columns' && css`
    justify-content: center;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      justify-content: initial;
    }
  `}
`;

const Wrapper = styled.div`
  ${(props) => props.variant === 'columns' && css`
    display: grid;
    grid-auto-rows: auto;
    grid-gap: ${props.theme.grid.columnGap};
    grid-template-columns: 1fr;
    text-align: center;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      grid-template-columns: 1fr 1fr 1fr;
      text-align: left;
    }
  `}
  ${(props) => props.variant === 'hover' && css`
    background-color: white;
    padding: 1rem 2rem 2rem 2rem;
  `}
`;

export default styled(PerformanceCounter)`
  ${(props) => props.variant === 'hover' && css`
    position: absolute;
    z-index: ${props.theme.zIndex.percentages};
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: ${props.shadow};
    &:before {
      content: '';
      position: absolute;
      top: -1rem;
      left: 0;
      right: 0;
      background-color: white;
      height: 2rem;
    }
  `}
`;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Content from '../../components/content';
import Container from '../../components/container';
import InfoContent from './info-content';

export const query = graphql`
  fragment TextContactBlock on MarkdownRemarkFrontmatterBlocks {
    template
    content
  }
`;

const ContactLayout = ({
  className,
  // template,
  content,
}) => (
  <div className={className}>
    <Container>
      <Wrapper>
        <div>
          <StyledContent
            content={content}
            markdown
          />
        </div>
        <div>
          <InfoContent />
        </div>
      </Wrapper>
    </Container>
  </div>
);

export const StyledContent = styled(Content)``;

const Wrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 3fr 2fr;
    margin-top: ${(props) => props.theme.sectionSpace};
    margin-bottom: ${(props) => props.theme.sectionSpace};
  }
`;

export default styled(ContactLayout)``;

import React from 'react';
import styled from 'styled-components';

const LoadingIndicatorSVG = ({ className }) => (
  <Loader
    version="1.1"
    className={`loader ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="243 270 102 90"
    xmlSpace="preserve"
  >
    <g>
      <path
        className="triangle triangle-1"
        d="M292.2,298.7L244.6,328.6L244.6,273.8Z"
      />
      <path
        className="triangle triangle-2"
        d="M245.1,330.2L292.7,300.3L292.7,355.1Z"
      />
      <path
        className="triangle triangle-3"
        d="M294.6,300.3L341.6,330.2L294,355.1Z"
      />
      <path
        className="triangle triangle-4"
        d="M342,328.5L295.1,298.7L342.1,273.8L342,328.5Z"
      />
    </g>
  </Loader>
);

const Loader = styled.svg`
  @keyframes animate-triangle-1 {
    0% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    15% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    60% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    75% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    100% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
  }

  @keyframes animate-triangle-2 {
    0% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    10% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    25% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    70% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    85% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    100% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
  }

  @keyframes animate-triangle-3 {
    0% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    20% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    35% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    80% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    95% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    100% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
  }

  @keyframes animate-triangle-4 {
    0% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    30% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
    45% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    90% {
      opacity: 1;
      stroke-dashoffset: 0;
    }
    100% {
      opacity: 0;
      stroke-dashoffset: 166;
    }
  }

  width: 50px;

  .triangle {
    fill: none;
    stroke: currentColor;
    stroke-width: 3;
    stroke-linejoin: round;

    &-1 {
      stroke-dasharray: 165 167;
      stroke-dashoffset: 166;
      animation: animate-triangle-1 1200ms
        cubic-bezier(0.17, 0.67, 0.83, 0.67) 300ms infinite;
    }

    &-2 {
      stroke-dasharray: 165 167;
      stroke-dashoffset: 166;
      animation: animate-triangle-2 1200ms
        cubic-bezier(0.17, 0.67, 0.83, 0.67) 300ms infinite;
    }

    &-3 {
      stroke-dasharray: 165 167;
      stroke-dashoffset: 166;
      animation: animate-triangle-3 1200ms
        cubic-bezier(0.17, 0.67, 0.83, 0.67) 300ms infinite;
    }

    &-4 {
      stroke-dasharray: 165 167;
      stroke-dashoffset: 166;
      animation: animate-triangle-4 1200ms
        cubic-bezier(0.17, 0.67, 0.83, 0.67) 300ms infinite;
    }
  }
`;

export default LoadingIndicatorSVG;

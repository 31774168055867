import React from 'react';
import styled, { css } from 'styled-components';

export default styled(({ className, children, ...props }) => (
  <h2
    className={className}
    dangerouslySetInnerHTML={{ __html: children }}
    {...props}
  />
))`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    font-size: 2rem;
  }

  ${(props) => props.hasSubtitle && css`
    margin-bottom: 0;
  `}

  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;

export const Subtitle = styled(({ className, children, ...props }) => (
  <h3
    className={className}
    dangerouslySetInnerHTML={{ __html: children }}
    {...props}
  />
))`
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.17rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    font-size: 1.5rem;
  }

  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;

import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useIsNetlifyCMSContext } from "../../context/isNetlifyCMSContext";
import Container from "../../components/container";
import Content from "../../components/content";
import Title from "../../components/title";
import PreviewCompatibleImage from "../../components/preview-compatible-image";

export const query = graphql`
  fragment ReferencesBlock on MarkdownRemarkFrontmatterBlocks {
    title
    clients: cases {
      id
      frontmatter {
        reference {
          name
          function
          text
          clientPersonImage {
            publicURL
            childImageSharp {
              fluid(maxHeight: 50, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;

const References = ({ title, clients, className }) => {
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    clients = (clients || []).map((clientId) => ({
      id: clientId,
      frontmatter: {
        reference: {
          name: `[name of ${clientId}]`,
          function: `[function of ${clientId}]`,
          text: `[text of ${clientId}]`,
          clientPersonImage:
            "https://via.placeholder.com/100x100?text=Reference+image",
        },
      },
    }));
  }
  return (
    <section className={className}>
      {title && <Title>{title}</Title>}
      <Container>
        <Wrapper>
          {(clients || [])
            .filter(Boolean)
            .filter(({ frontmatter }) => Boolean(frontmatter.reference))
            .map(({ id, frontmatter: client }) => (
              <Client key={id}>
                <InnerClient>
                  <ImageWrapper>
                    <Image
                      image={client.reference.clientPersonImage}
                      alt={client.reference.name}
                    />
                  </ImageWrapper>
                  <div>
                    <Name
                      dangerouslySetInnerHTML={{
                        __html: client.reference.name,
                      }}
                    />
                    <Function>{client.reference.function}</Function>
                  </div>
                </InnerClient>
                <Content compact>
                  <p>{client.reference.text}</p>
                </Content>
              </Client>
            ))}
        </Wrapper>
      </Container>
    </section>
  );
};

const Name = styled.h3`
  font-size: 1.2rem;
  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;
const Function = styled.h4`
  font-size: 0.8rem;
`;

const Client = styled.article`
  position: relative;
  padding: 2rem 1rem 1rem 2rem;
  ${Content} {
    position: relative;
  }
`;

const Image = styled(PreviewCompatibleImage)`
  width: 95%;
`;

const ImageWrapper = styled.div`
  padding: 1rem 1rem 1rem 0;
  background: linear-gradient(
    ${(props) => props.theme.pastelOrange},
    ${(props) => props.theme.pastelOrange}
  );
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-size: 7rem;
  margin-bottom: 1rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
`;

const InnerClient = styled.div`
  padding-bottom: 0.5rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default styled(References)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

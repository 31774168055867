import React from 'react';
import styled from 'styled-components';

const ContactTextWithBlob = ({ className }) => (
  <section className={className}>
    Not static anymore
  </section>
);

export default styled(ContactTextWithBlob)``;

import { useState } from 'react';
import qs from 'query-string';

export default (endpoint, formName) => {
  const [body, setBody] = useState({
    'form-name': formName,
  });
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setData(null);
    setError(null);

    // let res;
    try {
      // res = await fetch(
      await fetch(
        // for netlify forms, post to self
        window.location.pathname,
        // endpoint,
        {
          method: 'POST',
          // body: JSON.stringify(body),
          body: qs.stringify(body),
          headers: {
            // 'Content-Type': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
    } catch (err) {
      console.error('cannot fetch', err);
      setSubmitting(false);
      setError(err.message);
      return;
    }

    // let json;
    // try {
    //   json = await res.json();
    // } catch (err) {
    //   console.error('cannot convert json', err);
    //   setSubmitting(false);
    //   setError(err.message);
    //   return;
    // }

    // setSubmitting(false);
    // setData(json);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'formSubmission',
      formName,
      url: window.location.href,
      endpoint,
    });

    setSubmitting(false);
    setData(true);
  };
  return {
    body,
    setBody,
    onSubmit,
    submitting,
    data,
    error,
  };
};

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PreviewCompatibleImage from '../../components/preview-compatible-image';

export const query = graphql`
  fragment Sla on MarkdownRemarkFrontmatter {
    title
    text
    slaImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 320, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
          presentationHeight
        }
      }
    }
    label
    slaSpecs {
      key
      value
    }
  }
`;

const Sla = ({
  className,
  label,
  slaImage,
  title,
  text,
  slaSpecs,
}) => (
  <div className={className}>
    {label && <LabelWrapper><Label>{label}</Label></LabelWrapper>}
    <Wrapper>
      <Content
        imageWidth={slaImage?.childImageSharp?.fluid?.presentationWidth}
        imageHeight={slaImage?.childImageSharp?.fluid?.presentationHeight}
      >
        <ImageWrapper>
          <PreviewCompatibleImage
            image={slaImage}
            alt={title}
          />
        </ImageWrapper>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Text>{text}</Text>
      </Content>
      {slaSpecs && slaSpecs.length > 0 && (
        <Specs>
          {slaSpecs.map((spec) => (
            <Spec key={spec.key}>
              <Spec.Key>{spec.key}</Spec.Key>
              <Spec.Value>{spec.value}</Spec.Value>
            </Spec>
          ))}
        </Specs>
      )}
    </Wrapper>
  </div>
);

const Label = styled.div`
  background-color: black;
  color: white;
  text-transform: uppercase;
  text-align: center;
  padding: 0.8rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};
`;

const LabelWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 3fr 2fr;
    ${Label} {
      grid-column: 2;
    }
  }
`;

const Wrapper = styled.div`
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 3fr 2fr;
    ${Label} {
      grid-column: 2;
    }
  }
`;

const Content = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-rows: ${(props) => `${props.imageHeight ?? '180'}px`} 1fr;
  grid-template-columns: ${(props) => `${props.imageWidth ?? '180'}px`} 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-rows: auto;
  }
`;

const ImageWrapper = styled.div`
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-row: 1 / 3;
  }
`;

const Title = styled.div`
  font-size: 1.2rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-top: auto;
  }
  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;

const Text = styled.div`
  grid-column: 1 / 3;
  margin-top: 1rem;
  line-height: 1.4;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-column: auto;
    margin-top: 0.5rem;
    margin-bottom: auto;
  }
`;

const Specs = styled.div`
  margin: 0 1rem;
  padding: 1rem 0;
  border-top: 1px solid ${(props) => props.theme.gray1};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin: 1rem 0;
    padding: 1rem 2rem;
    border-top: 0;
    border-left: 1px solid ${(props) => props.theme.gray1};
  }
`;

const Spec = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin: 0.5rem 0;
`;

Spec.Key = styled.div`
  font-size: 80%;
`;

Spec.Value = styled.div`
  font-family: ${(props) => props.theme.fontFamilyMedium};
  flex-grow: 1;
  white-space: nowrap;
  text-align: right;
  margin-left: 0.5rem;
`;

export default styled(Sla)`
  margin-bottom: 1rem;
`;

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';

import Icon from '../../../../../layout/icons';
import Button from '../../../../../components/button';

import i18n from '../../../../../i18n';
import { push } from '../../../../../analytics';


export const pricingPlanTableViewQuery = graphql`
  fragment PricingPlanTableView on MarkdownRemarkFrontmatter {
    title
    price
    setupfee

    specs {
      ram
      cpu
      storage
      disk
    }

    pricingPlanServer {
      frontmatter {
        title
        internalName
      }
    }

    pricingPlanOptions {
      included
      option {
        id
        frontmatter {
          title
        }
      }
    }

    internalLink {
      frontmatter {
        slug
      }
    }
    externalLink
  }
`;

const PricingPlanInTableView = ({
  className,
  pricingPlanServer,
  title,
  price,
  setupfee,
  specs: {
    ram,
    cpu,
    storage,
  },
  pricingPlanOptions,
  internalLink,
}) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);
  return (
    <section className={className}>
      <Row isOpen={isOpen}>
        <RowButton onClick={toggle}>
          <span>
            {isOpen && (<Icon icon="chevron-up" />)}
            {!isOpen && (<Icon icon="chevron-down" />)}
          </span>
          <span className="not-for-mobile">{pricingPlanServer?.frontmatter?.title}</span>
          <span>{title}</span>
          <span className="not-for-mobile">{ram}</span>
          <span className="not-for-mobile">{cpu}</span>
          <span className="not-for-mobile">{storage}</span>
          <span>{price},-</span>
          <span className="not-for-mobile">{setupfee},-</span>
          <span className="not-for-mobile">
          <FreeTrialButton
            variant="primaryGradient"
            url={internalLink?.frontmatter?.slug ?? i18n('pricingPlanTryForFreeUrl', '/gratis-uitproberen/')}
            onClick={() => push({
              event: 'freeTrial',
              plan: title,
              server: pricingPlanServer?.frontmatter.internalName,
            })}
          >
            {i18n('pricingPlanTryForFreeButtonText', 'Gratis uitproberen')}
          </FreeTrialButton>
        </span>
        </RowButton>
      </Row>
      {isOpen && (
        <Content>
          <div className="for-mobile">
            <MobileContentItem>
              <span>{i18n('pricingPlanServer', 'Server')}:</span>
              <span>{pricingPlanServer?.frontmatter?.title}</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanPlan', 'Pakket')}:</span>
              <span>{title}</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanRAM', 'RAM')}:</span>
              <span>{ram}</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanCPU', 'CPU')}:</span>
              <span>{cpu}</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanStorage', 'Opslagruimte')}:</span>
              <span>{storage}</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanPrice', 'Prijs')}:</span>
              <span>{price},-</span>
            </MobileContentItem>
            <MobileContentItem>
              <span>{i18n('pricingPlanSetupFee', 'Setup fee')}:</span>
              <span>{setupfee},-</span>
            </MobileContentItem>
          </div>
          <CheckList>
            {(pricingPlanOptions || []).map(({ included, option }) => (
              <li key={option.id}>
                <StyledIcon icon="checkmark" withGradient={included} />
                {option.frontmatter?.title}
              </li>
            ))}
          </CheckList>
          <div className="for-mobile" style={{ textAlign: 'center' }}>
            <MobileFreeTrialButton
              css="margin: 1rem auto; visibility: visible;"
              variant="primaryGradient"
              url={internalLink?.frontmatter?.slug ?? i18n('pricingPlanTryForFreeUrl', '/gratis-uitproberen/')}
              onClick={() => push({
                event: 'freeTrial',
                plan: title,
                server: pricingPlanServer?.frontmatter.internalName,
              })}
            >
              {i18n('pricingPlanTryForFreeButtonText', 'Gratis uitproberen')}
            </MobileFreeTrialButton>
          </div>
        </Content>
      )}
    </section>
  );
};

const FreeTrialButton = styled(Button)`
  visibility: hidden;
  margin-right: 1rem;
  width: 150px;
  && {
    padding: 0.5rem 1rem;
  }
`;

const MobileFreeTrialButton = styled(Button)`
  display: block;
  width: 100%;
`;

const RowButton = styled.div`
  flex: 1;
  padding: 1rem 0 1rem .5rem;
  align-items: center;
  background: unset;
  border: unset;
  cursor: pointer;

  display: grid;
  grid-auto-rows: auto;
  grid-gap: .5rem;
  grid-template-columns: 1fr 2fr 2fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 2fr 4fr 4fr 2fr 4fr 4fr 2fr 3fr 1fr;
  }

  ${Icon} {
    width: 1.5rem;
    color: ${(props) => props.theme.secondaryColor};
    ${(props) => props.isOpen && css`
      color: ${props.theme.gray3};
    `}
  }
  
  span {
    text-align: center;
  }
`;

const CheckList = styled.ul`
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
  line-height: 1.5rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin: 0;
    column-count: 3;
  }
  li {
    position: relative;
    padding-left: 1.5rem;
  }
`;

const StyledIcon = styled(Icon)`
  width: 1rem;
  position: absolute;
  top: 0.2rem;
  left: 0;
  color: ${(props) => props.theme.successColor};
  ${(props) => props.icon === 'cross' && css`
    color: ${props.theme.errorColor};
  `}
`;

const MobileContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 0;
  span {
    flex: 1;
    &:last-child {
      font-family: ${(props) => props.theme.fontFamilyMedium};
    }
  }
`;
const Content = styled.div`
  padding: 1rem ;
  background-color: white;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding: 1rem;
  }
`;

const Row = styled.div`
  display: flex;
  background-color: white;
  padding: 0 0 0 .5rem;

  ${(props) => props.isOpen && css`
    background-color: ${props.theme.gray1};
    ${FreeTrialButton} {
      visibility: visible;
    }
  `}

  &:hover {
    background-color: rgb(233, 233, 233, 0.5);
    ${FreeTrialButton} {
      visibility: visible;
   }
  }

  align-items: center;
`;

export default styled(PricingPlanInTableView)`
  border-top: 1px solid ${(props) => props.theme.gray2};
  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.gray2};
  }
  .not-for-mobile {
    display: none;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      display: block;
    }
  }
  .for-mobile {
    display: block;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
     display: none;
    }
  }
`;

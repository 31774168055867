import React from 'react';
import styled from 'styled-components';
import { useSiteMetadata } from '../../context/siteMetadataContext';

const PopUpComponent = ({ className, togglePopUp }) => {
  const { email, tel, address } = useSiteMetadata();
  return (
    <div className={className}>
      <button onClick={togglePopUp} type="button">x</button>
      <h3>{address.name}</h3>
      <address>
        <span>{address.street} {address.number},</span>
        <span>{address.zipcode} {address.city}</span>
      </address>
      <a href={`mailto:${email}`}>{email}</a>
      <a href={`tel:${tel}`}>{tel}</a>
    </div>
  );
};

export default styled(PopUpComponent)`
  position: absolute;
  bottom: calc(100% + .5rem);
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  border-radius: .5rem;
  background-color: white;
  line-height: 1rem;
  text-align: left;
  display: none;
  font-size: .8rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
  }
  button {
    background: none;
    border: none;
    padding: none;
    position: absolute;
    top: .4rem;
    right: .2rem;
  }
  h3 {
    padding-bottom: .5rem;
  }
  address {
    padding-bottom: .5rem;
    span {
      display: block;
      white-space: nowrap;
    }
  }
  a {
    display: block;
    color: ${(props) => props.theme.primaryColor};
    white-space: nowrap;
    text-decoration: none;
  }
  &:after {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: .5rem solid transparent;
    border-right: .5rem solid transparent;
    border-top: .5rem solid white;
  }
`;

import React from 'react';
import styled, { css } from 'styled-components';

export default ({ toggleSwitch, switched }) => (
  <Wrapper>
    <Title>Development</Title>
    <div css="position: relative; padding: 1rem;">
      <Blob />
      <Switch switched={switched}>
        <input onClick={toggleSwitch} type="checkbox" name="checkit" />
        <span />
      </Switch>
    </div>
    <Title>Production</Title>
  </Wrapper>
);

const Title = styled.h4`
  text-transform: uppercase;
  font-size: .6rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    font-size: 1rem;
  }
`;

const size = '2';
const Switch = styled.label`
  position: relative;
  z-index: ${(props) => props.theme.zIndex.overBlob};
  display: block;
  cursor: pointer;
  background: ${(props) => props.theme.gray1};
  width: ${size}rem;
  height: 1rem;
  border-radius: ${size}rem;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  span {
    height: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr 1fr;
    transition: .2s;
    ${(props) => props.switched && css`
      grid-template-columns: 1fr 1fr 0fr;
    `}
    &::after {
      content: '';
      border-radius: 50%;
      background: ${(props) => props.theme.primaryColor};
      grid-column: 2;
      transition: all .2s;
    }

  }
`;

const Blob = styled.span`
  background: ${(props) => props.theme.gray2};
  height: 100%;
  width: 100%;
  clip-path: polygon(75% 0,100% 25%,100% 100%,25% 100%,0 75%,0 0);
  left: 0;
  position: absolute;
  top: 0;
`;

const Wrapper = styled.div`
  max-width: 26rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-bottom: 2rem;
  }
`;

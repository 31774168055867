import React from 'react';
import styled, { css } from 'styled-components';
import { graphql, Link } from 'gatsby';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Container from '../../components/container';
import Content from '../../components/content';
import Button from '../../components/button';
import Emerce from '../../icons/emerce-labeled';
import FDGazellen from '../../icons/fd-gazellen-labeled';
import i18n from '../../i18n';
import { push } from '../../analytics';

export const query = graphql`
  fragment HeroBlock on MarkdownRemarkFrontmatterBlocks {
    template
    textColor
    textPosition
    content
    imagePosition
    heroBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    heroBlockImageBig: heroBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    buttons {
      variant
      internalLink {
        frontmatter {
          slug
        }
      }
      externalLink
      text
      targetBlank
      id
    }
    showHostingIcons
  }
`;

const Hero = (props) => {
  const {
    className,
    template,
    textColor,
    textPosition,
    content,
    heroBlockImage,
    imageAlt,
    buttons,
    imagePosition,
    heroBlockImageBig,
    showHostingIcons,
  } = props;
  const WrapperComponent = imagePosition === 'center'
    ? (p) => (
      <PreviewCompatibleImage
        imagePosition={imagePosition}
        asBackgroundImage
        {...p}
      >
        {p.children}
      </PreviewCompatibleImage>
    )
    : 'div';
  return (
    <section className={className}>
      <WrapperComponent
        image={imagePosition === 'center' ? heroBlockImageBig : heroBlockImage}
      >
        <StyledContainer template={template}>
          <Wrapper
            template={template}
            textPosition={textPosition}
            imagePosition={imagePosition}
          >
            <ContentWrapper
              template={template}
              textPosition={textPosition}
              imagePosition={imagePosition}
              textColor={textColor}
            >
              {template === 'productpage' && (
                <Icons center={imagePosition === 'center'}>
                  <Emerce />
                  <FDGazellen />
                </Icons>
              )}
              <StyledContent
                template={template}
                content={content}
                markdown
              />
              {buttons && (
                <ButtonWrapper>
                  {buttons.map((singleButton, i) => (
                    <Button
                      key={`key-${i}`}
                      block="hero"
                      button={singleButton}
                      variant={singleButton.variant}
                    />
                  ))}
                </ButtonWrapper>
              )}
              {showHostingIcons && (
                <>
                  <HostingTitle>{i18n('heroHostingTitle', 'Kies je hosting')}</HostingTitle>
                  <HostingWrapper>
                    <HostingLink to="/magento-hosting/" onClick={() => push({ event: 'magentoHosting' })}>
                      <HostingLinkContent>
                        <HostingIcon src="/img/magento_menu_icon.svg" alt="magento" />
                        <HostingLabel>Magento</HostingLabel>
                      </HostingLinkContent>
                    </HostingLink>
                    <HostingLink to="/shopware-hosting/" onClick={() => push({ event: 'shopwareHosting' })}>
                      <HostingLinkContent>
                        <HostingIcon src="/img/shopware_menu_icon.svg" alt="shopware" />
                        <HostingLabel>Shopware</HostingLabel>
                      </HostingLinkContent>
                    </HostingLink>
                    <HostingLink to="/akeneo-hosting/" onClick={() => push({ event: 'akeneoHosting' })}>
                      <HostingLinkContent>
                        <HostingIcon src="/img/akeneo_menu_icon.svg" alt="akeneo" />
                        <HostingLabel>Akeneo</HostingLabel>
                      </HostingLinkContent>
                    </HostingLink>
                  </HostingWrapper>
                </>
              )}
              {template === 'productpage' && (
                <ProductPageDisclaimer>
                  {i18n('heroProductPageNoTextUnderButton', 'Geen creditcard nodig | Makkelijk geregeld')}
                </ProductPageDisclaimer>
              )}
            </ContentWrapper>
            {imagePosition !== 'center' && (
              <ImageWrapper template={template} imagePosition={imagePosition}>
                <PreviewCompatibleImage
                  image={imagePosition === 'imageRightOutOfContainer' ? heroBlockImageBig : heroBlockImage}
                  alt={imageAlt}
                />
              </ImageWrapper>
            )}
          </Wrapper>
        </StyledContainer>
      </WrapperComponent>
    </section>
  );
};

const HostingTitle = styled.span`
  display: block;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fontFamilyBold};
  font-size: .8rem;
`;
const HostingIcon = styled.img`
  width: 4rem;
  transition: all .4s cubic-bezier(0.39, 0.575, 0.565, 1);
`;

const HostingLink = styled(Link)`
  display: inline-block;
  width: 4rem;
  overflow: hidden;
  border-radius: 2rem;
  height: 4rem;
  background: ${(props) => props.theme.pastelOrange};
  margin-right: 1rem;
  transition: width 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  color: inherit;
  text-decoration: none;
  pointer-events: all;

  &:hover, &:focus {
    width: 12rem;

    & ${HostingIcon} {
      transform: rotate(360deg);
    }
  }
`;

const HostingWrapper = styled.div`
  padding-top: 1rem;
  pointer-events: none;
  :not(:hover) ${HostingLink}:first-of-type {
    width: 12rem;
  }
`;

const HostingLinkContent = styled.div`
  width: 12rem;
  display: flex;
  align-items: center;
`;

const HostingLabel = styled.div`
  padding-left: 1rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 1.5rem;
  ${Button} {
    width: 100%;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
      width: auto;
      margin-right: 1rem;
    }
  }
`;
const StyledContent = styled(Content)`
  position:relative;
  z-index: ${(props) => props.theme.zIndex.overBlob};
  ${(props) => props.template === 'productpage' && css`
    h1 {
      margin-bottom: 1rem;
      font-size: 2.4rem;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        font-size: 3rem;
      }
    }
    p {
      line-height: 2;
    }
  `};
`;

const Icons = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => !(props.center) && css`
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      justify-content: left;
    }
  `}
  svg {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      width: 10rem;
    }
    + svg {
      margin-left: 1rem;
    }
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  order: 2;
  color: ${(props) => (props.textColor === 'dark' ? props.theme.fontColor : props.theme.white)};

  ${(props) => props.imagePosition === 'center' && css`
    padding-bottom: 1rem;
  `}

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    order: 1;
    padding-top: ${(props) => (props.template === 'compact' ? '5rem' : '7.5rem')};
    padding-bottom: ${(props) => (props.template === 'compact' ? '5rem' : '7.5rem')};
    ${(props) => props.imagePosition === 'imageRightOutOfContainer' && css`
    padding-bottom: 0;
    `}
    ${(props) => props.template === 'productpage' && css`
      padding-right: 1rem;
      padding-top: 0;
      padding-bottom: 0;
    `}
  }
  ${(props) => props.imagePosition === 'center' && css`
    text-align: center;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      width: 50%;
      margin: 0 auto;
    }
  `}
`;

const ImageWrapper = styled.div`
  position: relative;
  order: 1;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
    ${(props) => (props.imagePosition === 'right'
      || props.imagePosition === 'imageRightOutOfContainer') && css`
      order: 2;
    `}
    ${(props) => props.template === 'productpage' && css`
      padding-left: 1rem;
    `}
  }
  ${(props) => props.imagePosition === 'imageRightOutOfContainer' && css`
    margin-right: -5rem;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      margin-right: 0;
    }
  `}
  ${PreviewCompatibleImage} {
    ${(props) => props.imagePosition === 'imageRightOutOfContainer' && css`

      /* this is a problem if the hero is not the first block in the page */
      margin-top: -3rem;

      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        max-width: 182%;
        width: 182%;
        margin-left: -5rem;

        /* this is a problem if the hero is not the first block in the page */
        margin-top: -10rem;
        margin-bottom: -10rem;
      }
    `}
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  align-items: center;
  min-height: 22.22rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
  ${(props) => props.imagePosition === 'center' && css`
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      grid-template-columns: 1fr;
    }
  `}
  ${(props) => props.imagePosition === 'imageRightOutOfContainer' && css`
    grid-gap: 0;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      grid-gap: ${props.theme.grid.columnGap};
    }
  `}
`;

const StyledContainer = styled(Container)`
  ${(props) => props.template === 'productpage' && css`
    padding-top: 2rem;
    padding-bottom: 0;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      padding-top: 5rem;
    }
  `}
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding-bottom: 2rem;
  }
`;

const ProductPageDisclaimer = styled.p`
  margin: 0.5rem 0;
`;

export default styled(Hero)`
  margin-bottom: 3rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-bottom: ${(props) => props.theme.sectionSpace};
  }

  ${(props) => props.template === 'no-bottom-margin' && css`
    margin-bottom: 0;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        margin-bottom: 0;
    }
  `}

  ${(props) => props.template === 'productpage' && css`
    text-align: center;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      text-align: unset;
    }
  `}
`;

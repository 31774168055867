import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Container from '../../components/container';
import Content from '../../components/content';
import Title from '../../components/title';
import PerformanceCounter from '../../components/performance-counter';

export const query = graphql`
  fragment PercentagesBlock on MarkdownRemarkFrontmatterBlocks {
    title
    client: case {
      frontmatter {
        name
        percentages {
          performancePercentages
          performanceSeconds
          checkoutPercentages
          checkoutSeconds
          filteringPercentages
          filteringSeconds
        }
      }
    }
  }
`;

const Percentages = ({ client, className, title }) => {
  if (!client) {
    return 'No client selected';
  }
  // client will be a relationship string in NetlifyCMS preview
  const percentagesObj = client?.frontmatter ?? {
    name: `[name of ${client}]`,
    percentages: {
      performancePercentages: 10,
      performanceSeconds: 20,
      checkoutPercentages: 30,
      checkoutSeconds: 40,
      filteringPercentages: 50,
      filteringSeconds: 60,
    },
  };
  return (
    <section className={className}>
      <Container>
        <Content>
          <Title css="padding-bottom: 3rem;">{title}</Title>
        </Content>
        <PerformanceCounter
          variant="columns"
          percentages={percentagesObj.percentages}
        />
      </Container>
    </section>
  );
};

export default styled(Percentages)`
  background-color: ${(props) => props.theme.gray1};
  padding: 3rem 0;
  margin-bottom: ${(props) => props.theme.sectionSpace};
`;

import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Container from '../../../../components/container';
import Button from '../../../../components/button';
import i18n from '../../../../i18n';
import PricingPlan from '../../../pricing-plans/pricing-plan';
import Switch from './switch';
import AllPlans from './all-plans';

const Title = styled.h2`
  margin-bottom: 1rem;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-bottom: 2rem;
  }
`;

const Plans = styled.div`
  background-color: ${(props) => props.theme.gray1};
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;

const PricePlanWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    /* if 4 or more than 4 plans, layout is columns of 4 */
    ${(props) => props.plansAmount >= 4 && css`
      grid-template-columns: repeat(4, 1fr);
    `}
    /* if less then 4 plans, then the layout is as big as the amount of plans  */
    ${(props) => props.plansAmount < 4 && css`
      grid-template-columns: repeat(${props.plansAmount}, 1fr);
    `}
  }
`;

const ShowAllPlanInfo = styled.div`
  text-align: center;
  margin: 3rem 0;
  p {
    margin: 0 0 1rem 0;
  }
`;

const PricingPlanGroup = ({ className, edges }) => {
  const [switchIsProduction, setIsProductionSwitch] = useState(true);
  const [showAllPlanInfo, setShowAllPlanInfo] = useState(false);
  const buttonEl = useRef(null);
  useEffect(() => {
    if (buttonEl.current) {
      buttonEl.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [buttonEl, showAllPlanInfo]);

  const plans = edges
    .filter(({ node }) => (switchIsProduction
      ? node.frontmatter.developmentOrProduction === 'production'
      : node.frontmatter.developmentOrProduction === 'development'
    ))
    .sort((a, b) => (a.node.frontmatter.price > b.node.frontmatter.price ? 1 : -1));
  return (
    <div className={className}>
      <Plans>
        <Container>
          <Switch
            toggleSwitch={() => setIsProductionSwitch(!switchIsProduction)}
            switched={switchIsProduction}
          />
          <Title>{i18n('pricingplansTitle', 'Meest populaire pakketten')}</Title>
          <PricePlanWrapper
            plansAmount={plans.filter(({ node }) => node.frontmatter.isPopulair).length}
          >
            {plans
              .filter(({ node }) => node.frontmatter.isPopulair)
              .map(({ node }) => (
                <PricingPlan setShowAllPlanInfo={() => setShowAllPlanInfo(!showAllPlanInfo)} key={node.id} {...node.frontmatter} />
              ))}
          </PricePlanWrapper>
          <ShowAllPlanInfo>
            <p>{i18n('pricingplansPricesIncludeTax', 'Prijzen zijn exclusief de BTW')}</p>
            <div>
              <Button
                type="button"
                variant="primaryGradient"
                onClick={() => setShowAllPlanInfo(!showAllPlanInfo)}
              >
                {i18n('pricingplansShowAllPlanInfo', 'Bekijk alle pakketten')}
              </Button>
            </div>
          </ShowAllPlanInfo>
        </Container>
      </Plans>
      {showAllPlanInfo && <AllPlans ref={buttonEl} plans={plans} />}
    </div>
  );
};

export default styled(PricingPlanGroup)``;

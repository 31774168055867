import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {
  Tabs,
  TabPanel,
  TabList,
  Tab,
} from 'react-tabs';
import { useIsNetlifyCMSContext } from '../../../../context/isNetlifyCMSContext';
import Container from '../../../../components/container';
import Title from '../../../../components/title';
import PreviewCompatibleImage from '../../../../components/preview-compatible-image';
import Group from './group';

export const platformQuery = graphql`
  fragment PricingPlatform on MarkdownRemarkFrontmatter {
    title
    order
    pricingPlatformIcon {
      publicURL
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
  }
`;

const PricingPlans = ({ className, title }) => {
  const { platforms, plans } = useStaticQuery(graphql`
    query PricePlans {
      platforms: allMarkdownRemark(
        filter: {
          frontmatter: {
            pricingPlatformId: {ne: null}
          }
        },
        sort: {fields: frontmatter___order}
      ) {
        edges {
          node {
            frontmatter {
              pricingPlatformId
              ...PricingPlatform
            }
          }
        }
      }

      plans: allMarkdownRemark(filter: {frontmatter: {pricingPlanId: {ne: null}}}) {
        edges {
          node {
            id
            frontmatter {
              developmentOrProduction
              isPopulair
              pricingPlanId
              ...PricingPlan
            }
            tableView: frontmatter {
              ...PricingPlanTableView
            }
          }
        }
      }
    }
  `);

  const platformsWithPlans = platforms.edges.map((platform) => {
    const platformPlans = plans.edges.filter(({ node }) => (
      node.frontmatter.pricingPlanServer.frontmatter.pricingServerPlatform
      === platform.node.frontmatter.pricingPlatformId
    ));
    return { platform, platformPlans };
  });

  return (
    <section className={className} id="static-pricing-plans">
      {title && (
        <Container>
          <Title>{title}</Title>
        </Container>
      )}
      <Tabs>
        <Container>
          <StyledTabList>
            {platformsWithPlans.map(({ platform }) => (
              <StyledTab
                key={`${platform.node?.frontmatter?.pricingPlatformId}-tab`}
                data-key={`${platform.node?.frontmatter?.pricingPlatformId}-tab`}
              >
                <PreviewCompatibleImage
                  image={platform.node?.frontmatter?.pricingPlatformIcon}
                  alt={platform.node?.frontmatter.title}
                />
              </StyledTab>
            ))}
          </StyledTabList>
        </Container>
        {platformsWithPlans.map(({ platform, platformPlans }) => (
          <TabPanel
            key={`${platform.node?.frontmatter?.pricingPlatformId}-content`}
            data-key={`${platform.node?.frontmatter?.pricingPlatformId}-content`}
          >
            <Group key={platform.node?.frontmatter?.pricingPlatformId} edges={platformPlans} />
          </TabPanel>
        ))}
      </Tabs>
    </section>
  );
};


const StyledTab = styled(Tab)`
  padding: .5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: .1rem solid ${(props) => props.theme.white};
  border-right: .1rem solid ${(props) => props.theme.white};
  cursor: pointer;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    padding: 1rem;
    width: 10rem;
    height: 10rem;
  }
  &:hover {
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
      background-color: ${(props) => props.theme.gray1};
    }
  }
  &.react-tabs__tab--selected {
    background-color: ${(props) => props.theme.gray1};
    width: 5rem;
    height: 5rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
      width: 10rem;
      height: 10rem;
    }
  }
  ${PreviewCompatibleImage} {
    width: 100%;
  }
`;

const StyledTabList = styled(TabList)`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;


const CheckPreviewForPricingPlans = ({ className, title }) => {
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    return (
      <div className={className}>
        {title && <h1>{title}</h1>}
        <p>Pricing plans will show here</p>
      </div>
    );
  }
  return <PricingPlans className={className} title={title} />;
};

export default styled(CheckPreviewForPricingPlans)`
  margin-bottom: ${(props) => props.theme.sectionSpace};
`;

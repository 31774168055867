import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Video from '../../components/video';

export const query = graphql`
  fragment VideoBlock on MarkdownRemarkFrontmatterBlocks {
    video
    videoTitle
  }
`;

const VideoBlock = ({ video, videoTitle, className }) => (
  <section className={className}>
    <Container small>
      <Video url={video} title={videoTitle} />
    </Container>
  </section>
);


export default styled(VideoBlock)`
  margin-bottom: ${(props) => props.theme.sectionSpace};
  ${Container} {
    padding-left: 0;
    padding-right: 0;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      padding-left: ${(props) => props.theme.grid.containerGap};
      padding-right: ${(props) => props.theme.grid.containerGap};
    }
  }
`;

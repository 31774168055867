import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useIsNetlifyCMSContext } from '../../context/isNetlifyCMSContext';
import Container from '../../components/container';
import Title from '../../components/title';
import Faq from './faq';

export const query = graphql`
  fragment FaqsBlock on MarkdownRemarkFrontmatterBlocks {
    title
    faqs {
      frontmatter {
        question
        answer
      }
    }
  }
`;

const Faqs = ({ className, title, faqs }) => {
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    faqs = (faqs || []).map((faqId) => ({
      id: faqId,
      frontmatter: {
        question: `[question of ${faqId}]`,
        answer: `[answer of ${faqId}]`,
      },
    }));
  }
  return (
    <section className={className}>
      <Container small>
        <Title>{title}</Title>
        <Wrapper>
          {(faqs || []).filter(Boolean).map(({ frontmatter: faq }, index) => (
            <Faq faq={faq} key={`${faq.question}-${index}`} />
          ))}
        </Wrapper>
      </Container>
    </section>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.gray2};
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export default styled(Faqs)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

import React from 'react';
import loadable from '@loadable/component';
import Loader from './loader';

const Component = loadable(() => import('./metrics'), {
  fallback: <Loader />,
});

const PerformanceMetrics = (props) => <Component {...props} />;

export default PerformanceMetrics;

import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Title, { Subtitle } from '../../components/title';
import Icon from '../../layout/icons';
import i18n from '../../i18n';
import { useSiteMetadata } from '../../context/siteMetadataContext';

export const query = graphql`
    fragment SupportBlock on MarkdownRemarkFrontmatterBlocks {
        title
    }
`;

const Support = ({ className, title, subtitle }) => {
  const { email, supportEmail, tel } = useSiteMetadata();
  return (
    <section className={className}>
      <Container small>
        {title && (
          <Title hasSubtitle={Boolean(subtitle)}>{title}</Title>
        )}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Wrapper>
          <ContactItem href={`mailto:${supportEmail}`}>
            <Icon icon="paper-plane" withGradient />
            <ContactItemTitle>
              {i18n(
                'supportBlockContactEmailTitle',
                'Stuur ons een e-mail'
              )}
            </ContactItemTitle>
            <span>{supportEmail}</span>
          </ContactItem>
          <ContactItem href={`tel:${tel}`}>
            <Icon icon="phone" withGradient />
            <ContactItemTitle>
              {i18n('supportBlockContactTelTitle', 'Bel met ons')}
            </ContactItemTitle>
            <span>{tel}</span>
          </ContactItem>
        </Wrapper>
      </Container>
    </section>
  );
};

const ContactItemTitle = styled.span`
    display: block;
    font-family: ${props => props.theme.fontFamilyMedium};
    font-size: 1.5rem;
    padding: 1rem 0 0.5rem 0;
`;

const ContactItem = styled.a`
    display: block;
    padding: 2rem;
    color: ${props => props.theme.fontColor};
    text-decoration: none;
    text-align: center;
    @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
        min-width: 18rem;
    }
    &:first-child {
        margin-bottom: 1rem;
        @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
            margin-bottom: 0;
        }
    }
    &:hover {
        ${Icon} {
            transform: rotate(10deg);
        }
    }
    ${Icon} {
        width: 3rem;
        color: ${props => props.theme.primaryColor};
        background: linear-gradient(
            270deg,
            ${props => props.theme.primaryColor},
            ${props => props.theme.secondaryColor}
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        transition: all 0.2s ease 0s;
    }
`;

const Wrapper = styled.div`
    @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export default styled(Support)`
    padding-bottom: ${props => props.theme.sectionSpace};
`;

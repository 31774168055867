import loadable from '@loadable/component';
import React from 'react';
import Loader from '../performance-metrics/loader';

const Component = loadable(() => import('./performance-scan-compare'), {
  fallback: <Loader />,
});

const PerformanceScanCompare = (props) => <Component {...props} />;

export default PerformanceScanCompare;

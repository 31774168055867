import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Loader from './loader';

export const query = graphql`
  fragment PerformanceScan on MarkdownRemarkFrontmatterBlocks {
    title
    internalLink {
      frontmatter {
        slug
      }
    }
    heroBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    hipexScoreIcon {
      publicURL
      childImageSharp {
        fluid(maxWidth: 200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`;

const Component = loadable(() => import('./performance-scan'), {
  fallback: <Loader />,
});

const PerformanceScan = (props) => <Component {...props} />;

export default PerformanceScan;

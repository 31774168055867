import React from "react";
import styled, { css } from "styled-components";
import { graphql } from "gatsby";
import Content from "../../components/content";
import PreviewCompatibleImage from "../../components/preview-compatible-image";
import Button from "../../components/button";
import i18n from "../../i18n";
import BlockRow from "../../components/block-row";
import Video from "../../components/video";

export const query = graphql`
  fragment CaseBlock on MarkdownRemarkFrontmatterBlocks {
    template
    text
    blockBackground
    caseBlockBottomImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 485, maxHeight: 275, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    caseBlockMiddleImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 330, maxHeight: 198, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    caseBlockTopImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 330, maxHeight: 189, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    topVideo
    singleCase: case {
      frontmatter {
        name
        externalLink
        internalLink {
          frontmatter {
            slug
          }
        }
        linkText
        caseLogo {
          publicURL
          childImageSharp {
            fluid(maxWidth: 236, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
        percentages {
          performancePercentages
          performanceSeconds
          checkoutPercentages
          checkoutSeconds
          filteringPercentages
          filteringSeconds
        }
      }
    }
  }
`;

const Case = ({
  singleCase,
  topVideo,
  blockBackground,
  text,
  className,
  template,
  caseBlockBottomImage,
  caseBlockMiddleImage,
  caseBlockTopImage,
}) => {
  // case will be a relationship string in NetlifyCMS preview
  const caseObj = singleCase?.frontmatter ?? {
    name: `[name of ${singleCase}]`,
    caseLogo: "https://via.placeholder.com/236x64?text=Case+logo",
    externalLink: "#",
    internalLink: "#",
  };
  return (
    <BlockRow
      blockBackground={blockBackground}
      template={template}
      block="case"
    >
      <section className={className}>
        <ImageWrapper>
          {caseBlockTopImage && !topVideo && (
            <TopImage
              image={caseBlockTopImage}
              alt={caseObj.name}
              template={template}
            />
          )}
          {topVideo && (
            <TopVideoWrapper template={template}>
              <Video url={topVideo} title={caseObj.name} />
            </TopVideoWrapper>
          )}
        </ImageWrapper>
        <ContentWrapper template={template}>
          <Logo image={caseObj.caseLogo} alt={caseObj.name} />
          <Content>
            <h2>
              <strong>{caseObj.name}</strong>
            </h2>
            <p>{text}</p>
          </Content>
          {(caseObj.internalLink?.frontmatter?.slug ||
            caseObj.externalLink) && (
            <Button
              css="margin-top: 1rem;"
              variant="primaryGradient"
              url={
                caseObj.internalLink?.frontmatter?.slug || caseObj.externalLink
              }
            >
              {caseObj.linkText || i18n("caseBlockButton", "Lees deze case")}
            </Button>
          )}
        </ContentWrapper>
      </section>
    </BlockRow>
  );
};

const Logo = styled(PreviewCompatibleImage)`
  margin-bottom: 1rem;
  max-width: 10rem;
`;

const TopImage = styled(PreviewCompatibleImage)`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  ${(props) =>
    props.template === "visuals-content" &&
    css`
      right: 0;
    `};
  ${(props) =>
    props.template === "content-visuals" &&
    css`
      left: 0;
    `};
`;

const TopVideoWrapper = styled.div`
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  ${(props) =>
    props.template === "visuals-content" &&
    css`
      right: 0;
    `};
  ${(props) =>
    props.template === "content-visuals" &&
    css`
      left: 0;
    `};
`;

const ContentWrapper = styled.div`
  padding-left: ${(props) => props.theme.grid.containerGap};
  padding-right: ${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: 20rem;
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export default styled(Case)`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  align-items: center;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
    ${(props) =>
      props.template === "content-visuals" &&
      css`
        ${ImageWrapper} {
          order: 2;
        }
      `}
  }
`;

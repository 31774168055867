import React from 'react';
import styled, { css } from 'styled-components';
import { useSiteMetadata } from '../../context/siteMetadataContext';
import Icon from '../../layout/icons';

export const StyledIcon = styled(Icon)`
  width: .8rem;
  margin-right: .5rem;
`;

const InfoLink = styled.a`
  display: flex;
  color: ${(props) => props.theme.fontColor};
  line-height: 2rem;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const Address = styled.address`
  display: flex;
  align-items: baseline;
  line-height: 2rem;
`;

const InfoTitle = styled.h3`
  margin-bottom: 1.5rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-top: 3rem;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoContent = () => {
  const {
    email,
    tel, 
    address,
  } = useSiteMetadata();
  return (
    <>
      <InfoTitle>{address.name}</InfoTitle>
      <InfoWrapper>
        <Address>
          <StyledIcon icon="marker" />
          <span>
            {address.street} {address.number}, <br />
            {address.zipcode} {address.city} <br />
          </span>
        </Address>
        <div css={css`line-height: 2;`}>
          <InfoLink href={`mailto:${email}`}><StyledIcon icon="paper-plane" />{email}</InfoLink>
          <InfoLink href={`tel:${tel}`}><StyledIcon icon="phone" />{tel}</InfoLink>
        </div>
      </InfoWrapper>
    </>
  );
};

export default InfoContent;

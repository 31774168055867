import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Content from '../../components/content';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import BlockRow from '../../components/block-row';

export const query = graphql`
  fragment TextImageBlock on MarkdownRemarkFrontmatterBlocks {
    template
    textTemplate
    content
    imageAlt
    textBackground
    imageVariant
    blockBackground
    textImageBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    textImageBlockImageBig: textImageBlockImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    ...ButtonComponents
  }
`;

const Text = (props) => {
  const {
    template,
    textTemplate,
    content,
    imageAlt,
    textImageBlockImage,
    textImageBlockImageBig,
    buttons,
    textBackground,
    imageVariant,
    blockBackground,
  } = props;
  return (
    <BlockRow
      blockBackground={blockBackground}
      template={template}
      block="text-image"
    >
      <TextImage
        template={template}
        textBackground={textBackground}
      >
        <ImageWrapper>
          <StyledImage
            imageVariant={imageVariant}
            image={imageVariant === 'imageOutOfContainer' ? textImageBlockImageBig : textImageBlockImage}
            template={template}
            alt={imageAlt}
          />
        </ImageWrapper>
        <ContentWrapper blockBackground={blockBackground}>
          <Content
            content={content}
            markdown
            template={template}
            textTemplate={textTemplate}
            textBackground={textBackground}
            block="text-image"
            buttons={buttons}
          />
        </ContentWrapper>
      </TextImage>
    </BlockRow>
  );
};

const ImageWrapper = styled.div`
  margin-left: -${(props) => props.theme.grid.containerGap};
  margin-right: -${(props) => props.theme.grid.containerGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const ContentWrapper = styled.div`
  ${(props) => props.blockBackground?.includes('Center') && css`
    padding: 1rem 2rem 0 2rem;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      padding: 0;
    }
  `}
`;

const StyledImage = styled(PreviewCompatibleImage)`
  ${(props) => props.imageVariant === 'imageOutOfContainer' && css`
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      max-width: 182%;
      width: 182%;
      ${(props.template === 'text-image-left' && css`
        margin-left: -82%;
      `)}
    }
  `}
`;

const TextImage = styled.div`
  ${ContentWrapper} {
    margin-top: 1rem;

    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      margin-top: 0;
    }
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${(props) => props.theme.grid.columnGap};
    align-items: center;

    ${(props) => (props.template === 'text-image-left' && css`
      ${props.textBackground && css`
        grid-template-columns: 2fr 1fr;
      `}
      ${ImageWrapper} {
        order: 1;
      };
      ${ContentWrapper} {
        order: 2;
      };
    `)}

    ${(props) => (props.template === 'text-image-right' && css`
      ${props.textBackground && css`
        grid-template-columns: 1fr 2fr;
      `}
      ${ImageWrapper} {
        order: 2;
      };
      ${ContentWrapper} {
        order: 1;
      };
    `)}
  }
`;

// const BlockRow = styled.div`
//   margin-bottom: ${(props) => props.theme.sectionSpace};
//   ${(props) => props.blockBackground && css`
//     padding: 2rem 0;
//     ${props.blockBackground.includes('square') && css`
//       ${props.blockBackground.includes('PastelOrange') && css`
//         background: linear-gradient(${props.theme.pastelOrange}, ${props.theme.pastelOrange});
//       `}
//       ${props.blockBackground.includes('LightGray') && css`
//         background: linear-gradient(${props.theme.gray1}, ${props.theme.gray1});
//       `}
//       ${props.blockBackground.includes('Center') && css`
//         background-repeat: no-repeat;
//         background-size: 60vw auto;
//         background-position: center;
//         ${(props.template === 'text-image-right' && css`
//           background-position: left 10rem center;
//         `)}
//         ${(props.template === 'text-image-left' && css`
//           background-position: right 10rem center;
//         `)}
//       `}
//     `}
//   `}
// `;

export default Text;

import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Content from '../../components/content';
import Container from '../../components/container';

export const query = graphql`
  fragment TextListBlock on MarkdownRemarkFrontmatterBlocks {
    texts {
      content
    }
  }
`;

const TextList = ({ texts, className }) => (
  <section className={className}>
    <Container>
      <Wrapper>
        {texts.map((text, i) => (
          <Content
            key={`${i}-${text.content}`}
            content={text.content}
            markdown
            block="text-list"
          />
        ))}
      </Wrapper>
    </Container>
  </section>
);

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default styled(TextList)`
  margin-bottom: ${(props) => props.theme.sectionSpace};
`;

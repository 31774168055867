import React from 'react';
import styled from 'styled-components';
import Icon from '../../layout/icons';
import PopUpComponent from './popup';

const MarkerComponent = ({ togglePopUp, popUp, className }) => (
  <div className={className}>
    <MarkerButton onClick={togglePopUp}>
      {popUp && (
        <PopUpComponent togglePopUp={togglePopUp} />
      )}
      <StyledIcon icon="hipex-marker" />
    </MarkerButton>
  </div>
);

const MarkerButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  cursor: pointer;
  user-select: none;
`;

const StyledIcon = styled(Icon)`
  width: 2rem;
`;

export default styled(MarkerComponent)`
  position: relative;
  width: 1px;
  height: 1px;
`;

import React from 'react';
import styled, { css } from 'styled-components';
import Container from '../../components/container';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Content from '../../components/content';
import Button from '../../components/button';
import Alert from '../../components/alert';
import DefaultInput from './default-input';
import useForm from './useForm';

const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Left = styled.div`
  position: relative;
`;

const RightForm = styled.form`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Image = styled(PreviewCompatibleImage)`
  width: 90%;
`;

const LeftContent = styled.div`
  flex-direction: column;
  justify-content: center;
  display: flex;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    position: absolute;
    top: 0;
    right: 0;
    left: 40%;
    min-height: 100%;
  }
`;

const InputWrapper = styled(DefaultInput)`
  margin-top: 0;
  flex: 1;

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-left: 40px;
  }
`;

const SubmitButton = styled(Button)`
  && {
    border: 0;
    height: 3rem;
  }
`;

const Newsletter = ({ frontmatter }) => {
  const {
    body,
    setBody,
    onSubmit,
    submitting,
    error,
    data,
  } = useForm(frontmatter.endpoint, frontmatter.name);
  const field = frontmatter.formFields.find((o) => o.name === 'email');

  if (!field) {
    return null;
  }

  return (
    <section>
      <Container css="margin-top: 2rem; margin-bottom: 2rem;">
        {error && (
          <Alert variant="danger" css={css`margin-bottom: 1rem`}>{frontmatter.errorMessage}</Alert>
        )}
        {data && (
          <Alert variant="success" css={css`margin-bottom: 1rem`}>{frontmatter.successMessage}</Alert>
        )}
        <Grid>
          <Left>
            <Image
              image={frontmatter.background}
            />
            <LeftContent>
              <Content content={frontmatter.content} markdown />
            </LeftContent>
          </Left>
          <RightForm
            onSubmit={onSubmit}
            name={frontmatter.name}
            id={frontmatter.anchorId}
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value={frontmatter.name} />
            <InputWrapper value={body.email}>
              <input
                name={field.name}
                id={field.name}
                value={body.email}
                type={field.type}
                onChange={(e) => setBody({ ...body, [field.name]: e.target.value })}
                required
              />
              <label htmlFor={field.name} type={field.type}>{field.placeholder} *</label>
            </InputWrapper>
            <SubmitButton
              variant="primaryGradientSquare"
              disabled={submitting}
              type="submit"
              ariaRole="submit"
            >
              {frontmatter.buttonText}
            </SubmitButton>
          </RightForm>
        </Grid>
      </Container>
    </section>
  )
};

export default Newsletter;

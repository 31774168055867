import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Content from '../../components/content';
import Container from '../../components/container';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Icon from '../../layout/icons';

export const query = graphql`
  fragment IconBlobTextBlock on MarkdownRemarkFrontmatterBlocks {
    content
    icon
    iconBlobTextImage {
      publicURL
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    removeSectionSpace
    ...ButtonComponent
  }
`;

const IconBlobText = (props) => {
  const {
    className,
    content,
    icon,
    iconBlobTextImage,
    button,
  } = props;
  return (
    <section className={className}>
      <Container>
        <div>
          {icon && (
            <StyledIcon icon={icon} />
          )}
          {(!icon && iconBlobTextImage) && (
            <StyledIcon as={PreviewCompatibleImage} image={iconBlobTextImage} alt="icon" />
          )}
        </div>
        <div>
          <StyledContent
            content={content}
            markdown
            textBackground="blobLightGray"
            block="icon-blob-text"
            button={button}
          />
        </div>
      </Container>
    </section>
  );
};

const StyledIcon = styled(Icon)`
  width: 6rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding-top: 2rem;
  }
`;

const StyledContent = styled(Content)`
  em {
    display: block;
  }
`;


export default styled(IconBlobText)`
  ${Container} {
    margin-bottom: ${(props) => (props.removeSectionSpace ? '0' : props.theme.sectionSpace)};
    max-width: 40rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 ${(props) => props.theme.grid.columnGap};
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      display: grid;
      grid-auto-rows: auto;
      grid-template-columns: 6rem 1fr;
      grid-gap: ${(props) => props.theme.grid.columnGap};
      padding: 2rem 0;
    }
  }
`;

import React from 'react';

function EmerceLabeled(props) {
  return (
    <svg
      viewBox="0 0 174 77.4"
      aria-hidden="true"
      focusable="false"
      role="img"
      fill="currentColor"
      {...props}
    >
      <path fill="#312B48" d="M135.6,56.1c0.1-2.6,0.1-5.9,0.2-9.3c0-1.6,0.3-3.3,0.9-4.8c1-2.6,3.1-4.5,5.8-5.2c2.6-0.8,5.4-0.8,8,0
	c3.3,1,5.7,3.8,6.2,7.2c0.3,1.5,0.4,3.1,0.4,4.7c0.1,5.4,0.1,10.8,0,16.1c0,1.8-0.1,3.6-0.6,5.4c-0.9,3.8-4.1,6.6-8,6.9
	c-1.9,0.3-3.8,0.2-5.7-0.3c-3.5-0.8-6.1-3.7-6.6-7.3c-0.3-1.5-0.4-3-0.4-4.5C135.6,62.5,135.6,59.7,135.6,56.1z M148.3,56.9
	L148.3,56.9v-6.5c0-1.6,0-3.2-0.1-4.7c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8l0,0c-0.1,0.6-0.1,1.2-0.1,1.7c0,5.3,0,10.7,0,16
	c0,1.6,0,3.3,0.1,4.9c0,0.8,0.5,1.4,1.2,1.6c1,0.3,2-0.2,2.3-1.1c0.1-0.2,0.1-0.5,0.1-0.8C148.3,64.3,148.3,60.6,148.3,56.9
	L148.3,56.9z"/>
<path fill="#312B48" d="M132.3,56.9c0,3,0.1,6.1,0,9.2c0,1.7-0.3,3.4-0.8,5c-1.1,3.4-4.1,5.8-7.7,6.2c-2,0.3-4.1,0.2-6.1-0.4
	c-3.4-1-6-3.9-6.4-7.5c-0.4-2.6-0.5-5.3-0.4-8c0-4.5,0-9,0.1-13.5c0-1.9,0.2-3.7,0.8-5.5c1.2-3.4,4.2-5.7,7.7-6.1
	c1.9-0.3,3.8-0.2,5.6,0.3c3.6,0.9,6.3,3.9,6.7,7.5c0.3,1.8,0.4,3.6,0.4,5.5c0.1,2.4,0,4.8,0,7.2L132.3,56.9z M123.5,56.9L123.5,56.9
	v-8.1c0-1.1,0-2.3-0.1-3.5c-0.1-0.9-0.8-1.6-1.8-1.5l0,0c-0.9,0-1.7,0.6-1.8,1.5c-0.1,0.3-0.1,0.7-0.1,1c0,6.2,0,12.3,0,18.5
	c0,1.2,0,2.4,0.1,3.5c0.1,0.9,0.8,1.6,1.7,1.6h0.1c0.9,0.1,1.8-0.6,1.9-1.5c0,0,0,0,0,0c0.1-0.3,0.1-0.7,0.1-1
	C123.6,63.9,123.6,60.4,123.5,56.9L123.5,56.9z"/>
<path fill="#312B48" d="M98.5,46.7L98,47c-1.4,1-2.9,2-4.3,3.1c-0.1,0.1-0.4,0.2-0.6,0.2c-0.1-0.1-0.2-0.3-0.2-0.5V42
	c0-0.4,0.2-0.8,0.5-1c1.8-1.3,3.5-2.6,5.3-3.9c0.3-0.2,0.6-0.2,0.9-0.2h7c0.6,0,0.8,0.1,0.8,0.7v38.6c0,0.6-0.1,0.8-0.7,0.8h-7.1
	c-0.7,0-0.9-0.2-0.9-0.9L98.5,46.7L98.5,46.7z"/>
<path fill="#312B48" d="M25.6,47.2l2-7.6c0.2-0.7,0.4-1.5,0.6-2.2c0.1-0.3,0.4-0.6,0.7-0.5h3.5c0.3,0,0.5,0.1,0.5,0.5v19.1
	c0,0.8,0,0.8-0.8,0.8h-2.6c-0.6,0-0.7-0.2-0.7-0.7v-10l-0.1-0.1c-0.1,0.2-0.1,0.3-0.1,0.5c-0.6,2-1,4.1-1.6,6.1
	c-0.1,0.5-0.3,0.6-0.8,0.6c-0.5-0.1-0.9-0.1-1.4,0c-0.5,0.1-0.6-0.2-0.8-0.6c-0.5-1.9-1-4-1.5-5.9c0-0.3-0.2-0.5-0.4-0.7v10.1
	c0,0.5-0.1,0.8-0.8,0.8c-0.9,0-1.8-0.1-2.7,0c-0.6,0-0.8-0.1-0.8-0.8V37.6c0-0.5,0.1-0.7,0.6-0.7h3.3c0.4,0,0.7,0.2,0.8,0.6l0,0
	c0.8,3,1.7,6.1,2.6,9.2c0.1,0.2,0.1,0.3,0.1,0.5L25.6,47.2z"/>
<path fill="#312B48" d="M59,57.5h-2.9c-2,0-2,0-2.5-1.9l-1.4-5.1c-0.1-0.3-0.1-0.7-0.7-0.6s-0.3,0.5-0.3,0.8v6.3
	c0,0.5-0.2,0.7-0.7,0.7h-3.2c-0.8,0-0.8,0-0.8-0.8V37.5c0-0.4,0.1-0.5,0.5-0.5c2.1,0,4.2-0.1,6.3,0.1c2.5-0.1,4.7,1.8,5,4.4
	c0.3,1.5,0.3,3.1-0.2,4.6c-0.2,0.9-0.7,1.7-1.5,2.4c-0.3,0.2-0.4,0.5-0.2,0.8 M51.1,43.5v2c0,0.2,0.1,0.4,0.2,0.5
	c0.9,0.3,1.9-0.1,2.2-1l0,0l0,0v-0.1c0.1-0.6,0.2-1.3,0.1-1.9c-0.1-1.5-0.7-2-2.1-2c-0.3,0-0.4,0.1-0.4,0.5V43.5z"/>
<path fill="#312B48" d="M11.4,37h4.3c0.5,0,0.7,0.1,0.7,0.7c-0.1,1-0.1,2,0,3c0,0.6-0.2,0.7-0.7,0.7h-4c-0.4,0-0.6,0.1-0.6,0.5v2.6
	c0,0.4,0.1,0.6,0.6,0.6h3.5c0.5,0,0.6,0.1,0.6,0.6v3c0,0.5-0.2,0.6-0.7,0.6h-3.5c-0.4,0-0.6,0.1-0.6,0.5v2.8c0,0.5,0.1,0.6,0.6,0.6
	h4.2c0.4,0,0.6,0.1,0.6,0.6V57c0,0.5-0.2,0.6-0.6,0.6H7c-0.6,0-0.7-0.2-0.7-0.7v-19c0-0.6,0.2-0.8,0.8-0.8C8.6,37.1,10,37,11.4,37z"
	/>
<path fill="#312B48" d="M39.6,41.3v3.4c0,0.1,0.3,0.3,0.5,0.3c1.2,0,2.3,0.1,3.5,0c0.5,0,0.7,0.1,0.6,0.6c0,1-0.1,2,0,3
	c0,0.5-0.2,0.7-0.7,0.6h-3.9v4h3.7c1.6,0,1.6,0,1.6,1.6V57c0,0.4-0.1,0.6-0.6,0.6h-8.9c-0.4,0-0.6-0.1-0.6-0.5V37.6
	c0-0.4,0.1-0.5,0.6-0.5h8.9c0.5,0,0.6,0.1,0.6,0.6v3c0,0.5-0.1,0.7-0.6,0.6H39.6z"/>
<path fill="#312B48" d="M77.7,53.3h4.5c0.8,0,0.8,0,0.8,0.8V57c0,0.4-0.1,0.6-0.6,0.6h-8.9c-0.5,0-0.6-0.2-0.6-0.6V37.6
	c0-0.5,0.1-0.6,0.6-0.6h8.8c0.5,0,0.6,0.1,0.6,0.6c-0.1,1,0,2,0,3c0,0.5-0.1,0.6-0.6,0.6h-4.7V45h4c0.4,0,0.6,0.1,0.6,0.6v3.2
	c0,0.5-0.2,0.6-0.6,0.6h-3.5c-0.4,0-0.6,0.1-0.6,0.5C77.7,51,77.7,52.1,77.7,53.3z"/>
<path fill="#312B48" d="M60,47.3c0.1-1.9-0.1-3.9,0.1-5.8c0.2-2.8,2.5-4.9,5.3-4.8c0.7-0.1,1.5,0,2.2,0.2c2.5,0.4,4.3,2.7,4.1,5.2
	c-0.1,0.2-0.2,0.4-0.4,0.5l-3.5,0.6c-0.4,0.1-0.6-0.1-0.6-0.5c0-0.3-0.1-0.7-0.2-1c-0.1-0.5-0.7-0.9-1.2-0.7h-0.1
	c-0.4,0.1-0.7,0.5-0.8,0.9c-0.1,0.4-0.1,0.9-0.1,1.3v8.3c0,0.4,0,0.7,0.1,1.1c0,0.6,0.5,1.1,1.1,1c0.5,0,0.9-0.4,1-0.8l0,0
	c0.1-0.3,0.1-0.5,0.1-0.8c0-0.4,0.2-0.5,0.6-0.5c1.2,0.2,2.4,0.4,3.5,0.5c0.5,0.1,0.5,0.3,0.4,0.7c-0.4,4.2-3.3,5.5-6.6,5.1
	s-4.8-2.3-5-5.7C60,50.4,60,48.8,60,47.3L60,47.3z"/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="12.6,0.6 16.3,8.1 24.6,9.4 
	18.6,15.2 20,23.5 12.6,19.5 5.2,23.5 6.6,15.2 0.6,9.4 8.9,8.1 "/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="40.8,0.6 44.5,8.1 52.8,9.4 
	46.8,15.2 48.2,23.5 40.8,19.5 33.4,23.5 34.8,15.2 28.8,9.4 37.1,8.1 "/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="69.7,0.6 73.4,8.1 81.7,9.4 
	75.7,15.2 77.1,23.5 69.7,19.5 62.2,23.5 63.7,15.2 57.7,9.4 65.9,8.1 "/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="97.9,0.6 101.6,8.1 109.9,9.4 
	103.9,15.2 105.3,23.5 97.9,19.5 90.5,23.5 91.9,15.2 85.9,9.4 94.2,8.1 "/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="125.6,0.6 129.3,8.1 137.6,9.4 
	131.6,15.2 133,23.5 125.6,19.5 118.2,23.5 119.6,15.2 113.6,9.4 121.9,8.1 "/>
<polygon fill="#312B48" stroke="#312B48" stroke-width="0.52" stroke-miterlimit="10" points="153.8,0.6 157.6,8.1 165.9,9.4 
	159.9,15.2 161.3,23.5 153.8,19.5 146.4,23.5 147.8,15.2 141.8,9.4 150.1,8.1 "/>
<polygon fill="#FFFFFF" stroke="#312B48" stroke-width="0.21" stroke-miterlimit="10" points="153.8,0.6 157.6,8.1 165.9,9.4 
	159.9,15.2 161.3,23.5 153.8,19.5 "/>
<path fill="#312B48" d="M7.4,76.9v-1.4l0.9-0.8L9,74.1c0.2-0.2,0.4-0.3,0.6-0.5l0.5-0.5c0.4-0.4,0.7-0.7,0.9-1
	c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.6-0.4-0.9-0.3c-0.3,0-0.6,0.1-0.9,0.3
	c-0.3,0.2-0.4,0.6-0.5,0.9l-1.8-0.3c0.1-0.5,0.3-1,0.6-1.4C8.2,68.3,8.6,68,9,67.8c0.9-0.4,1.9-0.4,2.7-0.1c0.4,0.1,0.7,0.4,1,0.6
	c0.3,0.3,0.5,0.6,0.6,0.9c0.2,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.4-0.4,0.8-0.7,1.1c-0.4,0.5-0.8,0.9-1.2,1.3
	c-0.2,0.2-0.4,0.4-0.7,0.6l-0.7,0.6h3.6v1.7L7.4,76.9z"/>
<path fill="#312B48" d="M18.5,77.1c-1,0-2-0.5-2.6-1.3c-0.3-0.5-0.6-1-0.7-1.5c-0.2-0.7-0.2-1.3-0.2-2c0-0.7,0.1-1.3,0.2-2
	c0.2-0.5,0.4-1.1,0.8-1.5c0.3-0.4,0.7-0.8,1.1-1c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0.1,1.5,0.3c0.4,0.2,0.8,0.6,1.1,1
	c0.3,0.5,0.6,1,0.7,1.5c0.2,0.7,0.2,1.3,0.2,2c0,0.7-0.1,1.3-0.2,2c-0.1,0.5-0.4,1.1-0.7,1.5c-0.3,0.4-0.7,0.8-1.1,1
	C19.6,77,19,77.1,18.5,77.1z M18.5,75.3c0.5,0,1-0.3,1.2-0.8c0.6-1.5,0.6-3.1,0-4.5c-0.3-0.7-1-1-1.7-0.7c-0.3,0.1-0.6,0.4-0.7,0.7
	c-0.6,1.5-0.6,3.1,0,4.5C17.5,75,18,75.3,18.5,75.3L18.5,75.3z"/>
<path fill="#312B48" d="M25.2,76.9v-7.2l-1.9,1l-0.6-1.6l2.8-1.5h1.6v9.2L25.2,76.9z"/>
<path fill="#312B48" d="M31.4,77.1c-0.8,0-1.6-0.2-2.3-0.6l0.5-1.6c0.2,0.1,0.5,0.3,0.7,0.3c0.3,0.1,0.6,0.1,0.9,0.1
	c0.6,0,1.3-0.2,1.7-0.7c0.5-0.6,0.7-1.3,0.7-2c-0.2,0.3-0.5,0.5-0.8,0.7c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0-0.8-0.1-1.2-0.2
	c-0.4-0.1-0.7-0.4-1-0.6c-0.3-0.3-0.5-0.6-0.6-1c-0.2-0.4-0.2-0.8-0.2-1.2c0-0.9,0.4-1.7,1-2.2c0.3-0.3,0.7-0.5,1.1-0.7
	c0.4-0.2,0.9-0.2,1.3-0.2c1,0,2,0.4,2.6,1.2c0.3,0.4,0.6,0.9,0.8,1.4c0.2,0.6,0.3,1.3,0.3,1.9c0,0.7-0.1,1.4-0.3,2
	c-0.2,0.6-0.5,1.1-0.8,1.6c-0.4,0.4-0.8,0.8-1.3,1C32.7,76.9,32.1,77.1,31.4,77.1z M32.1,72.1c0.3,0,0.5-0.1,0.8-0.2
	c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.7c0-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.5-0.2-0.7-0.2
	c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5
	C31.6,72,31.8,72.1,32.1,72.1L32.1,72.1z"/>
<path fill="#312B48" d="M42.9,77.2c-0.5,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.7-0.3-1.1-0.6c-0.3-0.2-0.5-0.5-0.7-0.9
	c-0.2-0.3-0.2-0.7-0.2-1.1c0-0.5,0.2-1.1,0.5-1.5c0.3-0.5,0.7-0.8,1.2-1.1c-0.3-0.3-0.5-0.7-0.7-1.1c-0.1-0.3-0.2-0.7-0.2-1
	c0-0.3,0.1-0.7,0.2-1c0.1-0.3,0.3-0.6,0.6-0.8c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.7-0.2,1.1-0.2c0.3,0,0.7,0.1,1,0.2
	c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9c-0.1,1.1-0.8,2.1-1.8,2.5l1.7,1.8c0.2-0.3,0.3-0.7,0.4-1
	c0.1-0.4,0.2-0.8,0.2-1.2l1.7,0.1c-0.1,0.6-0.2,1.2-0.4,1.8c-0.2,0.5-0.4,1-0.7,1.5l1.8,1.8h-2.4l-0.6-0.6c-0.4,0.3-0.8,0.5-1.2,0.6
	C43.9,77.1,43.4,77.2,42.9,77.2z M41.4,74.2c0,0.2,0.1,0.5,0.2,0.7c0.2,0.2,0.4,0.4,0.6,0.5c0.3,0.1,0.6,0.2,0.8,0.2
	c0.5,0,1-0.2,1.4-0.4l-2.2-2.2c-0.2,0.1-0.5,0.3-0.6,0.6C41.5,73.7,41.4,74,41.4,74.2z M42,69.7c0,0.2,0.1,0.4,0.2,0.6
	c0.2,0.3,0.4,0.5,0.6,0.7c0.3-0.1,0.6-0.4,0.8-0.6c0.2-0.2,0.3-0.5,0.3-0.8c0-0.2-0.1-0.5-0.2-0.6c-0.2-0.2-0.4-0.3-0.7-0.2
	c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.3,0.2-0.3,0.3C42,69.4,42,69.5,42,69.7z"/>
<path fill="#312B48" d="M51.9,76.9v-1.4l0.9-0.8l0.7-0.6c0.2-0.2,0.4-0.3,0.5-0.5l0.5-0.5c0.4-0.4,0.7-0.7,0.9-1
	c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.5-0.4-0.9-0.3c-0.3,0-0.6,0.1-0.9,0.3
	c-0.3,0.2-0.4,0.6-0.5,0.9l-1.8-0.3c0.1-0.5,0.3-1,0.6-1.4c0.3-0.4,0.7-0.7,1.1-0.9c0.9-0.4,1.9-0.4,2.7-0.1
	c0.7,0.3,1.3,0.9,1.6,1.6c0.2,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.4-0.4,0.8-0.7,1.1c-0.4,0.5-0.8,0.9-1.2,1.3
	c-0.2,0.2-0.4,0.4-0.7,0.6l-0.7,0.6h3.6v1.7L51.9,76.9z"/>
<path fill="#312B48" d="M63,77.1c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.6-1.1-1c-0.3-0.5-0.6-1-0.7-1.5c-0.2-0.7-0.2-1.3-0.2-2
	c0-0.7,0.1-1.3,0.2-2c0.1-0.5,0.4-1.1,0.7-1.5c0.3-0.4,0.7-0.8,1.1-1c0.5-0.2,1-0.3,1.5-0.3c1,0,2,0.5,2.6,1.3
	c0.3,0.5,0.6,1,0.7,1.5c0.3,1.3,0.3,2.7,0,4c-0.1,0.5-0.4,1.1-0.7,1.5c-0.3,0.4-0.7,0.8-1.1,1C64,77,63.5,77.1,63,77.1z M63,75.3
	c0.5,0,1-0.3,1.2-0.8c0.6-1.5,0.6-3.1,0-4.5c-0.3-0.7-1-1-1.7-0.7c-0.3,0.1-0.6,0.4-0.7,0.7c-0.6,1.5-0.6,3.1,0,4.5
	C62,75,62.5,75.3,63,75.3z"/>
<path fill="#312B48" d="M67.6,76.9v-1.4l0.9-0.8l0.7-0.6c0.2-0.2,0.4-0.3,0.6-0.5l0.5-0.5c0.4-0.4,0.7-0.7,0.9-1
	c0.2-0.3,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.3-1c-0.2-0.2-0.5-0.4-0.9-0.3c-0.3,0-0.6,0.1-0.9,0.3
	c-0.3,0.2-0.4,0.6-0.5,0.9l-1.8-0.3c0.1-0.5,0.3-1,0.6-1.4c0.3-0.4,0.7-0.7,1.1-0.9c0.9-0.4,1.9-0.4,2.7-0.1
	c0.7,0.3,1.3,0.9,1.6,1.6c0.2,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.1c-0.2,0.4-0.4,0.8-0.7,1.1c-0.4,0.5-0.8,0.9-1.2,1.3
	c-0.2,0.2-0.4,0.4-0.7,0.6l-0.7,0.6H74v1.7L67.6,76.9z"/>
<path fill="#312B48" d="M78.7,77.1c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.6-1.1-1c-0.3-0.5-0.6-1-0.7-1.5c-0.2-0.7-0.2-1.3-0.2-2
	c0-0.7,0.1-1.3,0.2-2c0.1-0.5,0.4-1.1,0.7-1.5c0.3-0.4,0.7-0.8,1.1-1c0.5-0.2,1-0.3,1.5-0.3c1,0,2,0.5,2.6,1.3
	c0.3,0.5,0.6,1,0.7,1.5c0.3,1.3,0.3,2.7,0,4c-0.1,0.5-0.4,1.1-0.7,1.5c-0.3,0.4-0.7,0.8-1.1,1C79.8,77,79.2,77.1,78.7,77.1z
	 M78.7,75.3c0.5,0,1-0.3,1.2-0.8c0.6-1.5,0.6-3.1,0-4.5c-0.3-0.7-1-1-1.7-0.7c-0.3,0.1-0.6,0.4-0.7,0.7c-0.6,1.5-0.6,3.1,0,4.5
	C77.7,75,78.2,75.3,78.7,75.3L78.7,75.3z"/>
<circle fill="#FB7C11" cx="160.9" cy="30.2" r="13.1"/>
<path fill="#FFFFFF" d="M156.3,36v-8.7l-2.3,1.3l-0.8-1.9l3.3-1.8h1.9V36H156.3z"/>
<path fill="#FFFFFF" d="M165.1,36.2c-0.6,0-1.2-0.1-1.8-0.3c-0.5-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.9-0.9-1.4
	c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.6,0.1-1.2,0.3-1.8c0.2-0.5,0.5-1,0.9-1.4c0.8-0.8,1.9-1.3,3-1.3c0.6,0,1.2,0.1,1.7,0.3
	c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,0.8,1.4c0.2,0.6,0.3,1.2,0.3,1.8v0.6H163c0.1,0.6,0.3,1.1,0.7,1.5
	c0.4,0.4,0.9,0.6,1.4,0.5c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.6-0.5,0.8-0.8l2,0.7c-0.2,0.5-0.5,0.9-0.9,1.2c-0.4,0.3-0.9,0.6-1.4,0.8
	C166.1,36.1,165.6,36.2,165.1,36.2z M165,29c-0.5,0-0.9,0.2-1.2,0.5c-0.4,0.4-0.6,0.8-0.7,1.3h3.8c-0.1-0.5-0.3-1-0.6-1.3
	C165.9,29.2,165.5,29,165,29z"/>
    </svg>
  )
}


export default EmerceLabeled;

import React from "react";
import { graphql } from "gatsby";
import styled, { css } from "styled-components";
import Container from "../../components/container";
import PreviewCompatibleImage from "../../components/preview-compatible-image";
import Button from "../../components/button";
import Title from "../../components/title";
import getButtonElement from "../../helpers/get-button-element";

export const query = graphql`
  fragment CasesBlock on MarkdownRemarkFrontmatterBlocks {
    title
    cases {
      id
      frontmatter {
        name
        externalLink
        internalLink {
          frontmatter {
            slug
          }
        }
        linkText
        caseLogo {
          publicURL
          childImageSharp {
            fluid(maxWidth: 650, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
      }
    }
    template
    caseVariant
  }
`;

const Cases = ({ cases, className, title, template, caseVariant }) => (
  <section className={className}>
    <Container>
      {title && <Title>{title}</Title>}
      <Wrapper template={template}>
        {(cases || []).filter(Boolean).map((node) => {
          // case will be a relationship string in NetlifyCMS preview
          const singleCase = node?.frontmatter ?? {
            name: `[name of ${node}]`,
            externalLink: node,
            linkText: `[link to ${node}]`,
          };

          const link =
            node?.frontmatter?.externalLink ??
            node?.frontmatter?.internalLink?.frontmatter?.slug;
          const linkProps = link ? getButtonElement(link) : {};

          return (
            <Case
              logoOnly={caseVariant !== "onlyLogo"}
              key={node.id ?? node.toString()}
            >
              <ImageWrapper {...linkProps}>
                <PreviewCompatibleImage
                  css={{ maxWidth: "70%" }}
                  image={singleCase.caseLogo}
                  alt={singleCase.name}
                />
              </ImageWrapper>
              {caseVariant !== "onlyLogo" && (
                <ContentWrapper>
                  <PartnerTitle>{singleCase.name}</PartnerTitle>
                  <Button
                    variant="secondaryWithArrow"
                    url={
                      singleCase.internalLink?.frontmatter?.slug ||
                      singleCase.externalLink
                    }
                  >
                    {singleCase.linkText}
                  </Button>
                </ContentWrapper>
              )}
            </Case>
          );
        })}
      </Wrapper>
    </Container>
  </section>
);

const PartnerTitle = styled.h6`
  margin-bottom: 0.75rem;
  font-size: 1rem;
`;

const ImageWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
`;

const ContentWrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const Case = styled.article`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.logoOnly &&
    css`
      border: 1px solid ${props.theme.gray1};
    `}
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${(props) =>
    props.template === "5-columns" &&
    css`
      grid-template-columns: 1fr 1fr;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    `}
`;

export default styled(Cases)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

export default styled(({ url, className, title }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '500px',
  });
  return (
    <div className={className} ref={ref}>
      {inView && (
        <iframe title={title} src={`https://www.youtube.com/embed/${url}?autoplay=0&autohide=1&rel=0`} frameBorder="0" />
      )}
    </div>
  );
})`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  background-color: ${(props) => props.theme.primaryColor};
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* rounding in chrome cause a faint pink border around the iframe */
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    border: 0;
  }
`;

/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-use-before-define */
import React from "react";
import styled, { css } from "styled-components";
import { graphql } from "gatsby";
import Content from "../../components/content";
import Button from "../../components/button";
import Alert from "../../components/alert";
import DefaultInput from "./default-input";
import RadioInput from "./radio-input";
import CheckInput from "./check-input";
import useForm from "./useForm";

export const query = graphql`
  fragment FormFrontmatter on MarkdownRemarkFrontmatter {
    name
    title
    endpoint
    content
    buttonText
    successMessage
    errorMessage
    anchorId
    background {
      publicURL
      childImageSharp {
        fluid(maxWidth: 564, maxHeight: 228, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    formFields {
      element
      type
      name
      placeholder
      defaultValue
      required
      options {
        value
        label
      }
      children {
        element
        type
        name
        placeholder
        defaultValue
        required
        options {
          value
          label
        }
      }
    }
  }
`;

const switchType = (type) => {
  switch (type) {
    case "radio":
      return RadioInput;
    case "checkbox":
      return CheckInput;
    default:
      return DefaultInput;
  }
};

function renderFieldset(field, body, setBody, template) {
  return (
    <Fieldset template={template}>
      <Legend>{field.name}</Legend>
      {/* this span is the 2nd child, so the first form field
          will match the :nth-child selector again. */}
      <span />
      {renderFormFields(field.children, body, setBody, template)}
    </Fieldset>
  );
}

function renderFormFields(fields, body, setBody, template) {
  return fields.map((field, index) => {
    if (field.element === "fieldset") {
      // If this field is in an event position, add a dummy span for :nth selector
      const extra = index % 2 === 0 ? <span /> : <></>;
      return (
        <>
          {renderFieldset(field, body, setBody, template)}
          {extra}
        </>
      );
    }
    const value = body[field.name] ?? (field.defaultValue || "");
    const Element = field.element || "input";
    const Input = switchType(field.type);
    // make sure this works for radio's with a shared name
    const id = `${field.name}-${field.defaultValue || ""}-${
      field.placeholder || ""
    }`;

    if (field.element === "select") {
      return (
        <Input key={`${field.name}-${index}`} value={value} template={template}>
          <select
            name={field.name}
            id={id}
            require={field.required}
            value={value}
            onChange={(e) => setBody({ ...body, [field.name]: e.target.value })}
          >
            <option />
            {field.options?.map(({ value: v, label }) => (
              <option value={v} key={v}>
                {label}
              </option>
            ))}
          </select>
          <label htmlFor={id} type={field.type}>
            {field.placeholder}
            {field.required && "*"}
          </label>
        </Input>
      );
    }

    if (field.type === "domain") {
      return (
        <Input key={`${field.name}-${index}`} value={value} template={template}>
          <Element
            name={field.name}
            id={id}
            type="text"
            required={field.required}
            value={value}
            onBlur={(e) => {
              let val = e.target.value;
              if (val.length > 0 && !val.startsWith("https://")) {
                val = val.replace(/[a-z]+:\/\//, "");
                val = `https://${val}`;
              }
              setBody({ ...body, [field.name]: val });
            }}
            onChange={(e) => setBody({ ...body, [field.name]: e.target.value })}
          />
          <label htmlFor={id} type={field.type}>
            {field.placeholder}
            {field.required && "*"}
          </label>
        </Input>
      );
    }

    return (
      <Input key={`${field.name}-${index}`} value={value} template={template}>
        <Element
          name={field.name}
          id={id}
          type={field.type}
          required={field.required}
          value={value}
          onChange={(e) => setBody({ ...body, [field.name]: e.target.value })}
        />
        <label htmlFor={id} type={field.type}>
          {field.placeholder}
          {field.required && "*"}
        </label>
      </Input>
    );
  });
}

const Form = ({ className, form, template }) => {
  const { body, setBody, onSubmit, submitting, error, data } = useForm(
    form?.frontmatter?.endpoint,
    form?.frontmatter?.name
  );

  if (!form) {
    return null;
  }

  // @TODO: use Preview Context to show a nice message
  // for NetlifyCMS Preview
  if (typeof form === "string") {
    return form;
  }

  const { frontmatter } = form;

  return (
    frontmatter && (
      <div className={className}>
        {error && <Alert variant="danger">{frontmatter.errorMessage}</Alert>}
        {data && <Alert variant="success">{frontmatter.successMessage}</Alert>}
        {!data && (
          <form
            template={template}
            onSubmit={onSubmit}
            name={frontmatter.name}
            id={frontmatter.anchorId}
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <p className="hidden">
              <label>
                Don’t fill this out if you're human: <input name="bot-field" />
              </label>
            </p>

            <input type="hidden" name="form-name" value={frontmatter.name} />
            {template === "withTitle" && (
              <Content>
                <h3 dangerouslySetInnerHTML={{ __html: frontmatter.title }} />
              </Content>
            )}
            <FormGrid template={template}>
              {renderFormFields(
                frontmatter.formFields || [],
                body,
                setBody,
                template
              )}
            </FormGrid>
            <ButtonWrapper template={template}>
              <Button
                variant="primaryGradient"
                disabled={submitting}
                ariaRole="submit"
                type="submit"
              >
                {frontmatter.buttonText}
              </Button>
            </ButtonWrapper>
          </form>
        )}
      </div>
    )
  );
};

const FormGrid = styled.div`
  ${(props) =>
    props.template === "columns" &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
`;

const ButtonWrapper = styled.div`
  ${({ template }) =>
    template === "columns" &&
    css`
      display: flex;
      justify-content: center;
    `}
`;

const Fieldset = styled.fieldset`
  padding: 1rem 0;
  border: 0;
  margin: 0;
  /* in column layout, drop on new line */
  min-width: 100%;

  ${(props) =>
    props.template === "columns" &&
    css`
      display: flex;
      flex-wrap: wrap;
    `}
`;

const Legend = styled.legend`
  padding: 0 1rem 0 0;
  margin-top: 2rem;
  margin-bottom: -2rem;
  font-family: ${(props) => props.theme.fontFamilyBold};
`;

export default styled(Form)`
  .hidden {
    display: none;
  }

  ${Button} {
    margin-top: ${(props) => props.theme.formGap};
    ${(props) =>
      props.template === "withTitleInBlob" &&
      css`
        width: 100%;
      `}
  }
  ${Alert} {
    margin: 0 auto;
    width: auto;
  }
`;


import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { useInView } from 'react-intersection-observer';
import MarkerComponent from './marker';
import { useSiteMetadata } from '../../context/siteMetadataContext';

const handleApiLoaded = (map, maps, template) => {
  if (template === 'footer') {
    map.setOptions({
      disableDefaultUI: true,
      styles: [{
        stylers: [{
          saturation: -100,
        }],
      }],
    });
  }
};

const Map = ({ className, template }) => {
  const zoom = 11;
  const [popUp, setPopUp] = useState(false);
  const togglePopUp = () => setPopUp(!popUp);
  const { maps: mapsMeta } = useSiteMetadata();
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  return (
    <div className={className} ref={ref}>
      {inView && (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: mapsMeta.apiKey }}
          defaultCenter={{ lat: mapsMeta.lat, lng: mapsMeta.lng }}
          defaultZoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, template)}
        >
          <MarkerComponent
            togglePopUp={togglePopUp}
            popUp={popUp}
            lat={mapsMeta.lat}
            lng={mapsMeta.lng}
          />
        </GoogleMapReact>
      )}
    </div>
  );
};

export default styled(Map)`
  ${(props) => props.template === 'footer' && css`
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      height: 100%;
      width: 100%;
      margin-top: -6rem;
    }
  `}
  ${(props) => props.template === 'contact' && css`
    height: 12rem;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      height: 100%;
    }
  `}
`;

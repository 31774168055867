import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../../components/container';
import Title, { Subtitle } from '../../components/title';
import List from './list';

export const query = graphql`
  fragment FeaturesListsBlock on MarkdownRemarkFrontmatterBlocks {
    title
    subtitle
    featuresLists {
      title
      ...FeaturesListsBlockList
    }
  }
`;

const Features = ({
  className,
  title,
  subtitle,
  featuresLists,
}) => (
  <section className={className}>
    {title && (
      <Title hasSubtitle={subtitle}>{title}</Title>
    )}
    {subtitle && (
      <Subtitle>{subtitle}</Subtitle>
    )}
    <Container>
      <Wrapper>
        {(featuresLists || []).map((list, i) => (
          <List key={`list-${list.title || i}`} {...list} />
        ))}
      </Wrapper>
    </Container>
  </section>
);

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-gap: ${(props) => props.theme.grid.columnGap};
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default styled(Features)`
  padding-bottom: ${(props) => props.theme.sectionSpace};

  ${List}:not(:last-child) {
    padding-bottom: 2rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      padding-bottom: 0;
    }
  }
`;

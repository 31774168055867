import React, { forwardRef } from 'react';
import styled from 'styled-components';

import Container from '../../../../../components/container';
import PricingPlan from './plan';

import i18n from '../../../../../i18n';

const AllPricingPlans = ({ className, plans }, ref) => (
  <section ref={ref} className={className}>
    <Container css="padding: 0;">
      <Heading>
        <Row style={{ flex: 1 }}>
          <span />
          <span className="not-for-mobile">{i18n('pricingPlanServer', 'Server')}</span>
          <span>{i18n('pricingPlanPlan', 'Pakket')}</span>
          <span className="not-for-mobile">{i18n('pricingPlanRAM', 'RAM')}</span>
          <span className="not-for-mobile">{i18n('pricingPlanCPU', 'CPU')}</span>
          <span className="not-for-mobile">{i18n('pricingPlanStorage', 'Opslagruimte')}</span>
          <span>{i18n('pricingPlanPrice', 'Prijs')}</span>
          <span className="not-for-mobile">{i18n('pricingPlanSetupFee', 'Setup fee')}</span>
          <span className="not-for-mobile" />
        </Row>
        <Spacer />
      </Heading>
      {(plans || []).map(({ node }) => (
        <PricingPlan key={node.id} {...node.tableView} />
      ))}
    </Container>
  </section>
);

const Row = styled.div`
  flex: 1;
  padding: 1rem 0 1rem .5rem;
  align-items: center;
  background: unset;
  border: unset;

  display: grid;
  grid-auto-rows: auto;
  grid-gap: .5rem;
  grid-template-columns: 1fr 2fr 2fr;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 2fr 4fr 4fr 2fr 4fr 4fr 2fr 3fr 1fr;
  }
`;

const Spacer = styled.div`
  width: 150px;
  display: none;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
  }
`;

const Heading = styled.header`
  display: flex;
  background-color: white;
  padding: 0 0 0 .5rem;
  text-align: center;

  background-color: ${(props) => props.theme.gray1};
  font-family: ${(props) => props.theme.standoutFontFamily};
`;

export default styled(forwardRef(AllPricingPlans))`
  margin-top: 3rem;

  .not-for-mobile {
    display: none;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      display: block;
    }
  }
`;

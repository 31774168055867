import React from 'react';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import Content from '../../components/content';
import Container from '../../components/container';
import Button from '../../components/button';
import ButtonWrapper from '../../components/button/wrapper';

export const query = graphql`
  fragment TextBlock on MarkdownRemarkFrontmatterBlocks {
    content
    template
    textBackground
    textTemplate
    removeSectionSpace
    ...ButtonComponents
  }
`;

const Text = ({
  className,
  content,
  textTemplate,
  textBackground,
  template,
  buttons,
}) => (
  <section className={className}>
    <Container>
      <Template template={template}>
        <div>
          <Content
            content={content}
            markdown
            textTemplate={textTemplate}
            block="text"
            template={template}
            textBackground={textBackground}
          />
          <ButtonWrapper>
            {buttons?.map((btn, i) => (
              <Button block="text" button={btn} key={i} />
            ))}
          </ButtonWrapper>
        </div>
      </Template>
    </Container>
  </section>
);

const Template = styled.div`

  ${(props) => props.template?.startsWith('small-') && css`
    display: flex;
    ${Content} {
      max-width: 40rem;
    }
  `}

  ${(props) => props.template === 'small-center' && css`
    justify-content: space-around;
  `}

  ${(props) => props.template?.includes('textcenter') && css`
    text-align: center;
  `}

  ${(props) => props.template === 'small-right' && css`
    justify-content: flex-end;
  `}

  ${(props) => props.template === 'small-left' && css`
    justify-content: flex-start;
  `}
`;

export default styled(Text)`
  margin-bottom: ${(props) => (props.removeSectionSpace ? '0' : props.theme.sectionSpace)};
`;

import React from 'react';
import styled from 'styled-components';
import Content from '../../components/content';

const Todo = ({ className, content }) => (
  <section className={className}>
    <Content
      content={content}
      markdown
    />
  </section>
);

export default styled(Todo)`
  background-color: yellow;
`;

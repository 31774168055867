import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Container from '../../components/container';
import Form from './form';
import Newsletter from './newsletter';

export const query = graphql`
  fragment FormBlock on MarkdownRemarkFrontmatterBlocks {
    template
    form {
      frontmatter {
        ...FormFrontmatter
      }
    }
  }
`;

const FormBlock = ({ className, form, template }) => {
  if (!form) {
    return null;
  }

  // @TODO: use Preview Context to show a nice message
  // for NetlifyCMS Preview
  if (typeof form === 'string') {
    return form;
  }

  const { frontmatter } = form;

  if (template === 'newsletter' && frontmatter) {
    return <Newsletter frontmatter={frontmatter} />;
  }

  return frontmatter && (
    <section className={className}>
      <Container small>
        <Form
          form={form}
          template={template}
        />
      </Container>
    </section>
  );
};

export default styled(FormBlock)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

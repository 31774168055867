import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { useIsNetlifyCMSContext } from '../../context/isNetlifyCMSContext';
import Container from '../../components/container';
import Content from '../../components/content';
import Title, { Subtitle } from '../../components/title';
import PreviewCompatibleImage from '../../components/preview-compatible-image';

export const query = graphql`
  fragment FeaturePreviewsBlock on MarkdownRemarkFrontmatterBlocks {
    title
    subtitle
    features {
      id
      frontmatter {
        name
        description
        featurePreviewImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 900 quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
        internalLink {
          frontmatter {
            slug
          }
        }
        externalLink
      }
    }
  }
`;

const FeaturePreviews = ({
  className,
  title,
  subtitle,
  features,
}) => {
  const [selectedFeature, setSelectedFeature] = useState((features || [{ id: null }])[0].id);
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    features = (features || []).map((featureId) => ({
      id: featureId,
      frontmatter: {
        name: `[name of ${featureId}]`,
        description: `[description of ${featureId}]`,
        featurePreviewImage: 'https://via.placeholder.com/100x100?text=Feature+image',
        externalLink: '#',
      },
    }));
  }

  return (
    <section className={className}>
      {title && (
        <Title hasSubtitle={Boolean(subtitle)}>{title}</Title>
      )}
      {subtitle && (
        <Subtitle>{subtitle}</Subtitle>
      )}

      <Container css="margin-top: 2rem">
        <StyledTabList>
          {(features || []).map(({ id, frontmatter: feature }, index) => (
            <StyledTab
              key={id}
              active={selectedFeature === id}
              onClick={() => setSelectedFeature(id)}
              index={index}
            >
              <Content compact>
                <h4>{feature.name}</h4>
                <p>{feature.description}</p>
              </Content>
            </StyledTab>
          ))}
          {(features || []).map(({ id, frontmatter: feature }, index) => (
            <Image
              key={id}
              active={selectedFeature === id}
              index={index}
            >
              <PreviewCompatibleImage
                image={feature.featurePreviewImage}
                alt={feature.name}
              />
            </Image>
          ))}
        </StyledTabList>
      </Container>
    </section>
  );
};

const Image = styled.div`
  height: 100%;
  grid-row: ${(props) => (props.index * 2) + 2};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-row: 1 / 4;
    grid-column: 2;
    ${(props) => !props.active && css`
      display: none;
    `}
  }
`;

const StyledTab = styled.button.attrs({ type: 'button' })`
  border: unset;
  margin: unset;
  background: unset;
  text-align: left;
  padding: 1.5rem 0;
  pointer-events: none;
  grid-row: ${(props) => (props.index * 2) + 1};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    padding: 1.5rem 1rem;
    grid-row: unset;
    pointer-events: unset;
    cursor: pointer;
  }
  h4 {
    font-size: 1.25rem;
    font-family: ${(props) => props.theme.fontFamilyBold};
  }

  ${(props) => props.active && css`
    cursor: initial;
    position: relative;
    @media all and (min-width: ${props.theme.breakingpoints.lg}) {
      background: ${props.theme.gray1};
      &:after {
        content: '';
        width: 2rem;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% - .2rem);
        background: ${props.theme.gray1};
        clip-path: polygon(22% 0, 61% 50%, 22% 100%, 0% 100%, 0 49%, 0% 0%);
      }
    }
  `}
`;

const StyledTabList = styled.div`
  display: grid;
  grid-auto-rows: auto;
  align-items: center;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-column-gap: ${(props) => props.theme.grid.columnGap};
    grid-template-columns: 1fr 1fr;
  }
`;

export default styled(FeaturePreviews)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

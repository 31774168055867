import React from 'react';
import styled from 'styled-components';

const CheckInput = ({ children, className }) => <div className={className}>{children}</div>;

export default styled(CheckInput)`
  margin-top: ${(props) => props.theme.formGap};
  display: flex;
  align-items: center;
  input {
    margin-right: 1rem;
  }
`;

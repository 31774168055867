import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { useIsNetlifyCMSContext } from '../../context/isNetlifyCMSContext';
import Icon from '../../layout/icons';
import Container from '../../components/container';
import Content from '../../components/content';
import Title, { Subtitle } from '../../components/title';
import PreviewCompatibleImage from '../../components/preview-compatible-image';

export const query = graphql`
  fragment FeaturesBlock on MarkdownRemarkFrontmatterBlocks {
    template
    title
    subtitle
    itemsToDisplayBeforeBreak
    showMoreTitle
    features {
      id
      frontmatter {
        name
        description
        featureImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
        internalLink {
          frontmatter {
            slug
          }
        }
        externalLink
      }
    }
  }
`;

const Features = ({
  className,
  template,
  title,
  subtitle,
  itemsToDisplayBeforeBreak = 6,
  showMoreTitle,
  features,
}) => {
  const [showAllItems, setShowAllItems] = useState(false);
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    features = (features || []).map((featureId) => ({
      id: featureId,
      frontmatter: {
        name: `[name of ${featureId}]`,
        description: `[description of ${featureId}]`,
        featureImage: 'https://via.placeholder.com/100x100?text=Feature+image',
        externalLink: '#',
      },
    }));
  }

  return (
    <section className={className}>
      {title && (
        <Title hasSubtitle={Boolean(subtitle)}>{title}</Title>
      )}
      {subtitle && (
        <Subtitle>{subtitle}</Subtitle>
      )}
      <Container small>
        <Wrapper>
          {(features || [])
            .slice(0, itemsToDisplayBeforeBreak)
            .map(({ id, frontmatter: feature }) => (
              <Feature key={id} template={template}>
                <div>
                  <div>
                    <PreviewCompatibleImage
                      image={feature.featureImage}
                      alt={feature.name}
                    />
                    <StyledContent compact>
                      <h4>{feature.name}</h4>
                      <p>{feature.description}</p>
                    </StyledContent>
                  </div>
                </div>
              </Feature>
            ))}
        </Wrapper>
        {(features || []).length > itemsToDisplayBeforeBreak && (
          <ShowMoreWrapper>
            <ShowMore onClick={() => setShowAllItems(!showAllItems)}>
              {showMoreTitle}
              <Icon icon="arrow-down" />
            </ShowMore>
          </ShowMoreWrapper>
        )}
      </Container>
      {showAllItems && (
        <Container>
          <SmallFeatureWrapper>
            {showAllItems && (features || [])
              .slice(itemsToDisplayBeforeBreak)
              .map(({ id, frontmatter: feature }) => (
                <SmallFeature key={id} template={template}>
                  <PreviewCompatibleImage
                    image={feature.featureImage}
                    alt={feature.name}
                  />
                  <StyledContent>
                    <h4>{feature.name}</h4>
                  </StyledContent>
                </SmallFeature>
              ))}
          </SmallFeatureWrapper>
        </Container>
      )}
    </section>
  );
};

const StyledContent = styled(Content)`
  h4 {
    font-family: ${(props) => props.theme.fontFamilyBold};
  }
`;

const Wrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Feature = styled.article`
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  ${(props) => props.template === 'align-left' && css`
    text-align: left;
  `}
  ${(props) => props.template === 'align-center' && css`
    text-align: center;
    ${PreviewCompatibleImage} {
      margin-left: auto;
      margin-right: auto;
    }
  `}

  ${PreviewCompatibleImage} {
    margin-bottom: 1rem;
  }
`;

const SmallFeatureWrapper = styled.div`
  margin: 1rem 0;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: ${(props) => props.theme.grid.columnGap};
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const SmallFeature = styled.div`
  display: flex;
  align-items: center;
  ${PreviewCompatibleImage} {
    max-width: 2rem;
    margin-right: 1rem;
    flex-grow: 1;
  }
`;

const ShowMore = styled.button.attrs({ type: 'button' })`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  font-family: ${(props) => props.theme.standoutFontFamily};
  font-size: 1rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};

  ${Icon} {
    margin-left: 0.5rem;
    width: 1rem;
  }
`;

const ShowMoreWrapper = styled.div`
  text-align: center;
  padding-top: 2rem;
`;

export default styled(Features)`
  padding-bottom: ${(props) => props.theme.sectionSpace};
`;

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useIsNetlifyCMSContext } from '../../context/isNetlifyCMSContext';
import PreviewCompatibleImage from '../../components/preview-compatible-image';
import Content from '../../components/content';
import Icon from '../../layout/icons';

export const query = graphql`
  fragment FeaturesListsBlockList on MarkdownRemarkFrontmatterBlocksFeaturesLists {
    title
    itemsToDisplayBeforeBreak
    features {
      id
      frontmatter {
        name
        description
        featureImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 85, maxHeight: 85, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              presentationWidth
            }
          }
        }
        internalLink {
          frontmatter {
            slug
          }
        }
        externalLink
      }
    }
  }
`;

const Features = ({
  className,
  title,
  itemsToDisplayBeforeBreak,
  features,
}) => {
  const [numberOfItemsToDisplay, setNumberOfItemsToDisplay] = useState(itemsToDisplayBeforeBreak);
  const isPreview = useIsNetlifyCMSContext();
  if (isPreview) {
    // eslint-disable-next-line no-param-reassign
    features = (features || []).map((featureId) => ({
      id: featureId,
      frontmatter: {
        name: `[name of ${featureId}]`,
        description: `[description of ${featureId}]`,
        featureImage: 'https://via.placeholder.com/100x100?text=Feature+image',
        externalLink: '#',
      },
    }));
  }

  const loadMore = () => {
    setNumberOfItemsToDisplay(features.length);
  };

  return (
    <section className={className}>
      {title && (
        <Title>{title}</Title>
      )}
      <div>
        {(features || []).slice(0, numberOfItemsToDisplay).map(({ id, frontmatter: feature }) => (
          <Feature key={id}>
            <PreviewCompatibleImage
              image={feature.featureImage}
              alt={feature.name}
            />
            <StyledContent compact>
              <h4>{feature.name}</h4>
              <Description>{feature.description}</Description>
            </StyledContent>
          </Feature>
        ))}
        {features.length > numberOfItemsToDisplay && (
          <ShowMoreWrapper>
            <ShowMore onClick={loadMore}>
              <Icon icon="arrow-down" />
            </ShowMore>
          </ShowMoreWrapper>
        )}
      </div>
    </section>
  );
};

const Title = styled.h3`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};
`;

const StyledContent = styled(Content)`
  && h4 {
    margin-bottom: 0;
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }
`;

const Description = styled.p``;


const ShowMore = styled.button.attrs({ type: 'button' })`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  font-size: 1rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  text-transform: uppercase;

  ${Icon} {
    margin-left: 0.5rem;
    width: 1rem;
  }
`;

const ShowMoreWrapper = styled.div`
  text-align: center;
  padding-top: 2rem;
`;

const Feature = styled.article`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  grid-template-columns: 1fr 3fr;
  padding-top: 1.5rem;
  ${PreviewCompatibleImage} {
    max-width: 85px;
  }
`;

export default styled(Features)``;
